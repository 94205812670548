import React, {useState} from 'react';
import {Modal, Button} from 'antd';
import {useTranslation} from "react-i18next";
import './termsAndConditions.scss';
import BookFlight from "../BookFlight/BookFlight";
import Footer from "../../components/Footer/Footer";

const TermsAndConditions = (props: any) => {

  const {t} = useTranslation();

  return (
      <div>
        <div className="terms-conditions-container">
          <ul className="ul1">
            <li className="li1"><strong>Juridiksioni i Kushteve t&euml; P&euml;rgjithshme
              t&euml; Biznesit:</strong> T&euml; gjitha sh&euml;rbimet e airtiketa.com (m&euml; tutje AT)
              p&euml;rdoren vet&euml;m n&euml; baz&euml; t&euml; kushteve t&euml; p&euml;rgjithshme
              t&euml; biznesit n&euml; vijim. P&euml;rjashtimet nga k&euml;to kushte t&euml; biznesit jan&euml; te
              vlefshme vet&euml;m n&euml;se jan&euml; b&euml;r&euml; si marr&euml;veshje me shkrim dhe
              jan&euml; v&euml;rtetuar nga ne, gjithashtu me shkrim.
            </li>
            <li className="li1"><strong>Oferta dhe Lidhja e Kontrat&euml;s:</strong> Ofertat p&euml;r sistemin
              online t&euml; rezervimit paraqesin nj&euml; k&euml;rkes&euml; t&euml; detyrueshme p&euml;r
              klientin. Me regjistrimin p&euml;rmes formularit online p&euml;r rezervim, klienti lidhet
              detyrimisht gjat&euml; p&euml;rmbylljes s&euml; nj&euml; kontrate p&euml;r transport me kushtet e
              AirTiketa. Me pranimin nga ana e AT, lidhet kontrata p&euml;r transportim. AT deklaron pranimin e
              rezervimit, n&euml;se konfirmimi d&euml;rgohet p&euml;rmes E-mail-it ose me ngarkimin e
              kreditkart&euml;s suaj.
            </li>
            <li className="li1"><strong>Kushtet e Pages&euml;s:</strong> &Ccedil;mimet jan&euml; ato
              t&euml; konfirmuara gjat&euml; rezervimit. Pagesa b&euml;het vet&euml;m p&euml;rmes
              nj&euml; kreditkarte t&euml; pranuar nga AT. N&euml;se banka ose instituti i kreditkartelave e
              refuzon pages&euml;n, AirTiketa &euml;sht&euml; e autorizuar p&euml;r nd&euml;rprerjen e
              kontrat&euml;s dhe anulimin e rezervimit. P&euml;r &ccedil;do pasagjer, n&euml; k&euml;t&euml; rast,
              ngrihet nj&euml; pages&euml; prej 30.00 &euro;.
            </li>
            <li className="li1"><strong>Premtim p&euml;r Konfidencialitet:</strong> AirTiketa garanton
              konfidencialitet t&euml; plot&euml; p&euml;r t&euml; gjitha t&euml; dh&euml;nat e pranuara nga
              klienti.
            </li>
            <li className="li1"><strong>Dokumentet e Udh&euml;timit:</strong> Pasagjeri merr konfirmin e rezervimit
              p&euml;rmes e-mail-it, i cili sh&euml;rben edhe si bilet&euml; udh&euml;timi. Udh&euml;timi pa
              dokument t&euml; vlefsh&euml;m nuk pranohet. Biletat e aeroplanit q&euml; kan&euml; ndryshime nga
              t&euml; dh&euml;nat personale t&euml; pasagjerit nuk pranohen si dokumente valide
              t&euml; udh&euml;timit. P&euml;r rishfaqjen e biletave humb&euml;se ose p&euml;r raste
              t&euml; tjera, t&euml; shkaktuara pa ndikimin e AirTiketa, ngrihet
              nj&euml; pages&euml; p&euml;rpunimi prej 30.00 &euro; p&euml;r secil&euml;n bilet&euml;.
            </li>
            <li className="li1"><strong>Ndryshimet e Rezervimeve/Anulimet:</strong> Ndryshimet dhe emrat mund
              t&euml; b&euml;hen n&euml; dispozicion dhe pas pages&euml;s s&euml; nj&euml; shume t&euml; caktuar.
              Anulimet b&euml;hen me shkrim n&euml;p&euml;rmjet Email-it (info@airtiketa.com)
              n&euml; dit&euml; pune deri n&euml; or&euml;n 18:00. Anulimet n&euml; dit&euml; festash ose
              t&euml; shtunave p&euml;rpunohen n&euml; dit&euml;n e par&euml; t&euml; pun&euml;s. Kushtet e
              anulimit jan&euml; si vijon:
            </li>
          </ul>
          <ul className="ul1">
            <li className="li1">10% p&euml;r anulime 30 dit&euml; para nisjes s&euml; udh&euml;timit,</li>
            <li className="li1">25% p&euml;r anulime 29 deri n&euml; 21 dit&euml;,</li>
            <li className="li1">50% p&euml;r anulime 21 deri n&euml; 14 dit&euml;,</li>
            <li className="li1">75% p&euml;r anulime 14 deri n&euml; 7 dit&euml;,</li>
            <li className="li1">100% p&euml;r anulime 7 dit&euml; para nisjes ose pas fillimit
              t&euml; udh&euml;timit. Ndryshimet e emrave pas koh&euml;s s&euml; nisjes s&euml; udh&euml;timit nuk
              pranohen.
            </li>
          </ul>
          <ul className="ul1">
            <li className="li1"><strong>Koha p&euml;r Check-in:</strong> Pasagjer&euml;t k&euml;shillohen
              t&euml; paraqiten tek sporteli p&euml;r p&euml;rgatitje m&euml; s&euml; voni 120 minuta para nisjes.
              P&euml;rgatitja e pasagjer&euml;ve mbyllet 40 minuta para nisjes s&euml; aeroplanit. AirTiketa nuk
              mban p&euml;rgjegj&euml;si p&euml;r paraqitje t&euml; vonuara.
            </li>
            <li className="li1"><strong>Konfirmimi i Kthimit:</strong> &Ccedil;do klient i AirTiketa duhet
              t&euml; konfirmoj&euml; kthimin e tij t&euml; planifikuar brenda 24 deri n&euml; 48 or&euml;ve,
              n&euml;p&euml;rmjet telefonit tek AirTiketa. Konfirmimet e kthimeve mund t&euml; b&euml;hen
              n&euml; numrat e telefonit q&euml; gjenden n&euml; bilet&euml;. N&euml;se nuk konfirmoni kthimin dhe
              ai humbet, humbet edhe e drejta p&euml;r fluturim.
            </li>
            <li className="li1"><strong>Bagazhi dhe Dokumentacioni:</strong> Limiti i lejuar p&euml;r bagazh
              p&euml;r secilin pasagjer &euml;sht&euml; 20 kg. Bagazhi i mbipeshuar mund t&euml; bartet pas
              pages&euml;s s&euml; nj&euml; tarife t&euml; caktuar. P&euml;r p&euml;rmbushjen dhe zbatimin e
              rregullave t&euml; hyrjes n&euml; shtetet tjera, secili pasagjer mban
              p&euml;rgjegj&euml;sin&euml; p&euml;r veten. AirTiketa &euml;sht&euml; e autorizuar
              q&euml; t&euml; llogaris&euml; shpenzimet p&euml;r mosrespektimin e k&euml;tyre rregullave.
            </li>
            <li className="li1"><strong>Lirime p&euml;r F&euml;mij&euml; dhe
              T&euml; Rinj:</strong> F&euml;mij&euml;t deri n&euml; 2 vje&ccedil; dhe t&euml; rinjt&euml; deri
              n&euml; 11 vje&ccedil; paguajn&euml; nj&euml; &ccedil;mim m&euml; t&euml; ul&euml;t. Mosha e
              f&euml;mij&euml;s gjat&euml; nisjes s&euml; udh&euml;timit &euml;sht&euml; e r&euml;nd&euml;sishme.
            </li>
            <li className="li1"><strong>Tjet&euml;rsimi:</strong> Tjet&euml;rsimet e k&euml;rkesave kund&euml;r
              AirTiketa jan&euml; t&euml; paefektshme.
            </li>
            <li className="li1"><strong>Reklamimet:</strong> Reklamat mund t&euml; b&euml;hen
              n&euml; m&euml;nyr&euml; t&euml; shkruar menj&euml;her&euml; pas p&euml;rfundimit t&euml; fluturimit
              tek adresa e caktuar.
            </li>
            <li className="li1"><strong>Vendi i Arbitrazhit:</strong> Vendi i arbitrazhit &euml;sht&euml; Gjykata
              Komerciale n&euml; Kosov&euml;.
            </li>
            <li className="li1"><strong>Klauzola Salvatoriane:</strong> N&euml;se klauzola t&euml; ve&ccedil;anta
              t&euml; rregullores jan&euml; t&euml;r&euml;sisht ose pjes&euml;risht t&euml; paefektshme,
              validiteti i rregulloreve t&euml; tjera mbetet i paprekur. Rregulloret e paefektshme
              z&euml;vend&euml;sohen me nj&euml; rregullore m&euml; t&euml; p&euml;raf&euml;rt.
            </li>
            <li className="li1"><strong>Q&euml;ndrimi:</strong> K&euml;to kushte i p&euml;rkasin q&euml;ndrimit
              t&euml; dat&euml;s 01.07.2020.
            </li>
          </ul>
        </div>
        <Footer/>
      </div>
  );
};

export default TermsAndConditions;
