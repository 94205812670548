import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import {AppState} from "../../store";
import FlightBox from "../../components/FlightBox/FlightBox";
import FlightRow from "../../components/FlightRow/FlightRow";
import styles from "./SelectFlight.module.scss";
import {ReactComponent as ArrowIcon} from "../../assets/images/right-arrow.svg";
import {FlightPriceService} from "../../actions";
import {Airport, FlightPriceBody} from "../../types/service-types/FlightPriceTypes";
import {Flight} from "../../types/service-types/FlightTypes";
import {DATE_FORMAT} from "../../utils/constants";
import {getQueryParameterByName} from "../../utils";

interface Props {
  departureDate: string;
  departureAirport: string;
  arrivalAirport: string;
  onFlightSelected: any;
  earliestDateAllowed?: any;
  onDateSelected?: any;
  totalPassengers: number;
  type?: string | 'DEPARTURE_FLIGHT'
}

const SelectFlight = ({
                        departureDate,
                        departureAirport,
                        arrivalAirport,
                        onFlightSelected,
                        earliestDateAllowed,
                        onDateSelected,
                        totalPassengers,
                        type
                      }: Props) => {

  const dispatch = useDispatch();
  const returnDate = getQueryParameterByName("return");

  const [arrival, setArrival] = useState<Airport | null>(null);
  const [departure, setDeparture] = useState<Airport | null>(null);

  const [weeklyFlights, setWeeklyFlights] = useState<Array<FlightPriceBody>>([]);
  const [dailyFlights, setDailyFlights] = useState<Array<Flight>>([]);
  const [dateOfDeparture, setDateOfDeparture] = useState<any>("");
  const oneWay = useSelector((state: AppState) => state.home.oneWay);
  const departureFlight = useSelector(
      (state: AppState) => state.selectedFlights.departureFlight
  );
  const [selectedDateOfDeparture, setSelectedDateOfDeparture] = useState("");

  const weeklyFlightsContainToday = weeklyFlights.find(flight => flight.dateOfDeparture === moment().format("DD/MM/YYYY"));
  const weeklyFlightsContainDepartureDate = earliestDateAllowed && weeklyFlights.find(flight => flight.dateOfDeparture === earliestDateAllowed);
  const hasPreviousFlights = !weeklyFlightsContainToday && !weeklyFlightsContainDepartureDate;

  const isMobile = window.innerWidth < 768;

  // on component mount, get the available dates
  useEffect(() => {
    const params = {
      dateOfDeparture: moment(departureDate).format("DD/MM/YYYY"),
      departureAirportCode: departureAirport,
      arrivalAirportCode: arrivalAirport,
      hasReturnFlight: !!returnDate
    }

    FlightPriceService.getWeeklyFlights(params)
        .then(res => {
          setWeeklyFlights(res);
        });
  }, []);

  // after getting the dates, find the available date and select it
  useEffect(() => {
    if (weeklyFlights.length) {

      if (selectedDateOfDeparture) {
        const selectedDate = weeklyFlights.find(flight => {
          const isBeforeEarliestDateAllowed = moment(flight.dateOfDeparture, DATE_FORMAT).isBefore(moment(earliestDateAllowed, DATE_FORMAT).add(1, "days"));
          const isEqualToSelectedDateOfDeparture = selectedDateOfDeparture === flight.dateOfDeparture;
          return !!flight.id && !isEqualToSelectedDateOfDeparture && !isBeforeEarliestDateAllowed;
        });
        if (selectedDate) {
          setSelectedDateOfDeparture(selectedDate.dateOfDeparture);
        } else {
          setSelectedDateOfDeparture(weeklyFlights[3].dateOfDeparture);
        }
      } else {
        setSelectedDateOfDeparture(weeklyFlights[3].dateOfDeparture);
      }
    }
  }, [weeklyFlights]);


  // handle selection of the dates
  const onFlightBoxClick = (flight: any) => {
    if (flight.id) {
      setSelectedDateOfDeparture(flight.dateOfDeparture);
    }
  }

  // if the date selected is changed, get the flights for that date
  useEffect(() => {
    const isBeforeDepartureFlight = departureFlight?.dateOfDeparture && type === 'RETURN_FLIGHT' ? moment(selectedDateOfDeparture, DATE_FORMAT).isBefore(moment(departureFlight?.dateOfDeparture, DATE_FORMAT)) : false;

    const params = {
      dateOfDeparture: selectedDateOfDeparture,
      departureAirportCode: departureAirport,
      arrivalAirportCode: arrivalAirport,
      hasReturnFlight: !!returnDate

    }
    if (selectedDateOfDeparture && !isBeforeDepartureFlight) {
      // console.log(selectedDateOfDeparture)
      FlightPriceService.getDailyFlights(params)
          .then(res => {
            setDailyFlights(res.flights);
            setArrival(res.arrivalAirport);
            setDeparture(res.departureAirport);
            onDateSelected(res.dateOfDeparture);
          }).catch(err => {
        setDailyFlights([]);
      });
    }
  }, [selectedDateOfDeparture]);

  const fetchNextAvailableFlights = () => {
    const params = {
      dateOfDeparture: weeklyFlights[3].dateOfDeparture,
      departureAirportCode: departureAirport,
      arrivalAirportCode: arrivalAirport,
      hasReturnFlight: !!returnDate

    }

    FlightPriceService.getNextAvailableFlights(params)
        .then(res => {
          setWeeklyFlights(res);
        });
  }

  const fetchPreviousAvailableFlights = () => {
    const params = {
      dateOfDeparture: weeklyFlights[3].dateOfDeparture,
      departureAirportCode: departureAirport,
      arrivalAirportCode: arrivalAirport,
      hasReturnFlight: !!returnDate
    }

    FlightPriceService.getPreviousAvailableFlights(params)
        .then(res => {
          setWeeklyFlights(res);
        });
  }

  return (
      <div className={styles.selectFlightContainer}>
        <div className={styles.flightBoxes}>

          {hasPreviousFlights &&
              <div className={styles.backButton}
                   onClick={fetchPreviousAvailableFlights}>
                  <ArrowIcon/>
              </div>}

          {weeklyFlights.map(flight => {
            const isBeforeEarliestDateAllowed = moment(flight.dateOfDeparture, DATE_FORMAT).isBefore(moment(earliestDateAllowed, DATE_FORMAT).add(1, "days"));
            const isBeforeDepartureFlight = moment(flight.dateOfDeparture, DATE_FORMAT).isBefore(moment(selectedDateOfDeparture, DATE_FORMAT));
            const isEqualToSelectedDateOfDeparture = earliestDateAllowed && (earliestDateAllowed === flight.dateOfDeparture);
            const flightHasNoPrice = (!flight.lowestPrice) && (!flight.lowestPriceWithTax);
            const isDisabled = flightHasNoPrice || isBeforeEarliestDateAllowed || (isEqualToSelectedDateOfDeparture && earliestDateAllowed);
            const isVisible = (!isMobile || isMobile || selectedDateOfDeparture === flight.dateOfDeparture)
            return isVisible &&
                <FlightBox
                    flight={flight}
                    disabled={isDisabled}
                    selected={selectedDateOfDeparture === flight.dateOfDeparture && !isDisabled && !isBeforeDepartureFlight}
                    onClick={() => !isDisabled && onFlightBoxClick(flight)}/>
          })}

          <div className={styles.forwardButton}
               onClick={fetchNextAvailableFlights}>
            <ArrowIcon/>
          </div>

        </div>
        {!!(arrival && departure) &&
            <div className={styles.flightRows}>
              {dailyFlights.map(flight => {
                    const isBeforeDepartureFlight = moment(flight.dateOfDeparture, DATE_FORMAT).isBefore(moment(selectedDateOfDeparture, DATE_FORMAT));
                    return <FlightRow
                        flight={flight}
                        arrival={arrival}
                        departure={departure}
                        departureDate={selectedDateOfDeparture}
                        disabled={flight.numberOfAvailableSeats === 0 || flight.numberOfAvailableSeats < totalPassengers}
                        isBefore={isBeforeDepartureFlight}
                        // disabledText="Currency Price is not matching"
                        onClick={() => flight.numberOfAvailableSeats !== 0 && flight.numberOfAvailableSeats >= totalPassengers && onFlightSelected(flight)}/>
                  }
              )}
            </div>}
      </div>
  )
}

export default SelectFlight;
