import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router";
import {useTranslation} from "react-i18next";
import {AppState} from "../../store";
import {resetHomeForm} from "../../store/home";
import {clearPassengers} from "../../store/passengers";
import {clearFlights} from "../../store/selectedFlights";
import {Button} from "antd";
import React from "react";

export interface BookFlightFooterProps {
  continue?: () => void;
  hideContinue?: boolean;
  disableContinue?: boolean;
  newSearchBtnHide?: boolean;
  totalPrice?: string | number;
}

const BookFlightFooter = (props: BookFlightFooterProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const {t} = useTranslation();

  const price = useSelector(
      (state: AppState) => state.selectedFlights.pricePreview
  );

  const newSearch = () => {
    dispatch(resetHomeForm());
    dispatch(clearPassengers());
    dispatch(clearFlights());
    history.push("/");
  };

  const totalPrice = props.totalPrice ? props.totalPrice : price;
  return (
      <div className="nav-bar">
        <div className="grandPrice">
          <p>Total payment per passenger(s)</p>
          <h1>{totalPrice}</h1>
        </div>

        <div className="nav-buttons">
          {!props.newSearchBtnHide && (
              <Button
                  className="button-text-primary mr-0"
                  htmlType="submit"
                  onClick={newSearch}
              >
                {t("app.bookFlight.footer.newSearch", "New search")}
              </Button>
          )}
          {!props.hideContinue && (
              <Button
                  className="button-primary"
                  htmlType="button"
                  disabled={props.disableContinue}
                  onClick={props.continue}
                  style={{marginLeft: 'auto'}}
              >
                {t("app.bookFlight.footer.continue", "Continue")}
              </Button>
          )}
        </div>
      </div>
  );
};

export default BookFlightFooter;
