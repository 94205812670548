import {createSlice, Dispatch} from '@reduxjs/toolkit';
import {AvailableDatesService} from "../actions";
import {addToLSObject} from "../utils";
import moment from "moment";

export const fetchAvailableDepartureDates = (body: any) => async (dispatch: Dispatch) => {
    const response = await AvailableDatesService.getDates(body);
    dispatch(setAvailableDepartureDates(response.availableDates));
}

export const fetchAvailableReturnDates = (body: any) => async (dispatch: Dispatch) => {
    const response = await AvailableDatesService.getDates(body);
    dispatch(setAvailableReturnDates(response.availableDates));
}

export const clearAvailableDates = () => async (dispatch: Dispatch) => {
    dispatch(clearDates());
}

export const setFromAirport = (body: any) => async (dispatch: Dispatch) => {
    dispatch(fromAirport(body));
}
export const setToAirport = (body: any) => async (dispatch: Dispatch) => {
    dispatch(toAirport(body));
}
export const setDates = (body: any) => async (dispatch: Dispatch) => {
    dispatch(dates(body));
}
export const setAdults = (body: any) => async (dispatch: Dispatch) => {
    dispatch(adults(body));
}
export const setChildren = (body: any) => async (dispatch: Dispatch) => {
    dispatch(children(body));
}
export const setInfants = (body: any) => async (dispatch: Dispatch) => {
    dispatch(infants(body));
}
export const setOneWay = (body: any) => async (dispatch: Dispatch) => {
    dispatch(oneWay(body));
}

export const resetHomeForm = () => (dispatch: Dispatch) => {
    dispatch(fromAirport(""));
    dispatch(toAirport(""));
    dispatch(dates([]));
    dispatch(clearDates());
    dispatch(adults(1));
    dispatch(children(0));
    dispatch(infants(0));
    dispatch(oneWay(false));
}

interface SelectedFlightSlice {
    fromAirport: string;
    toAirport: string;
    dates: Array<any>;
    adults: number;
    children: number;
    infants: number;
    availableDepartureDates: Array<any>;
    availableReturnDates: Array<any>;
    oneWay: boolean;
}

const initialState = (): SelectedFlightSlice => {
    let localStorageData;

    try {
        localStorageData = JSON.parse((window as any).localStorage.getItem("pr_jet_home_data"));
        if(localStorageData["dates"] && localStorageData["dates"].length) {
            if(localStorageData["dates"][0]) {
                localStorageData["dates"][0] = moment(localStorageData["dates"][0]);
            }
            if(localStorageData["dates"][1]) {
                localStorageData["dates"][1] = moment(localStorageData["dates"][1]);
            }
        }
    } catch (error) {
        localStorageData = null;
        console.warn('There was a problem with LS', error)
    }

    return {
        fromAirport: "",
        toAirport: "",
        dates: [],
        adults: 1,
        children: 0,
        infants: 0,
        availableDepartureDates: [],
        availableReturnDates: [],
        oneWay: false,
        ...localStorageData
    }
}

const stateSlice = createSlice({
    name: 'home',
    initialState: initialState(),
    reducers: {
        fromAirport: (state: any, action) => {
            addToLSObject("pr_jet_home_data", "fromAirport", action.payload);
            state.fromAirport = action.payload
        },
        toAirport: (state: any, action) => {
            addToLSObject("pr_jet_home_data", "toAirport", action.payload);
            state.toAirport = action.payload
        },
        dates: (state: any, action) => {
            addToLSObject("pr_jet_home_data", "dates", action.payload);
            state.dates = action.payload
        },
        adults: (state: any, action) => {
            addToLSObject("pr_jet_home_data", "adults", action.payload);
            state.adults = action.payload
        },
        children: (state: any, action) => {
            addToLSObject("pr_jet_home_data", "children", action.payload);
            state.children = action.payload
        },
        infants: (state: any, action) => {
            addToLSObject("pr_jet_home_data", "infants", action.payload);
            state.infants = action.payload
        },
        setAvailableDepartureDates: (state: any, action) => {
            state.availableDepartureDates = action.payload
        },
        setAvailableReturnDates: (state: any, action) => {
            state.availableReturnDates = action.payload
        },
        oneWay: (state: any, action) => {
            state.oneWay = action.payload
        },
        clearDates: (state: any) => {
            state.availableDepartureDates = []
            state.availableReturnDates = []
        },
    }
});

export const {fromAirport, toAirport, dates, adults, children, infants, setAvailableDepartureDates, setAvailableReturnDates, clearDates, oneWay} = stateSlice.actions;

export default stateSlice.reducer;
