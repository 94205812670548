import React, {useEffect, useState} from "react";
import "./Home.scss";
import Footer from "../../components/Footer/Footer";
import {Button, Col, DatePicker, Radio, Row, Select} from "antd";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {BookingBody} from "../../types/service-types/BookingTypes";
import {AppState} from "../../store";
import {Link, useHistory} from "react-router-dom";
import {DownOutlined, PlusOutlined} from "@ant-design/icons";
import DateUtils from "../../types/utils/DateUtils";
import HomepageOfferCard from "../../components/HomepageOfferCard";
import moment from "moment";
import classNames from "classnames";
import RangePickerPanel from "../../components/RangePickerPanel/RangePickerPanel";
import {preparePassengersLabel} from "../../utils";
import {ReactComponent as RefreshIcon} from "../../assets/images/refresh.svg";
import HomepagePassengersDropdown from "../../components/HomepagePassengersDropdown";
import {ReactComponent as CloseIcon} from "../../assets/images/close.svg";
import {
  clearAvailableDates,
  fetchAvailableDepartureDates,
  fetchAvailableReturnDates,
  setAdults,
  setChildren,
  setDates,
  setFromAirport,
  setInfants,
  setOneWay,
  setToAirport,
} from "../../store/home";
import {setDepartureFlight, setReturnFlight} from "../../store/selectedFlights";
import FrequentlyAskedQuestions from "../../components/FrequentlyAskedQuestions/FrequentlyAskedQuestions";
import Checkin from "../../components/Checkin/Checkin";
import {fetchAirports} from "../../store/countries";

const Homepage = ({}: any) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const history: any = useHistory();

  const {OptGroup} = Select;
  const {RangePicker} = DatePicker;

  const isMobile = window.innerWidth < 768;

  const airports = useSelector((state: AppState) => state.countries.countries);
  const offers = useSelector((state: AppState) => state.offers.offers);
  const departureDates = useSelector(
      (state: AppState) => state.home.availableDepartureDates
  );
  const returnDates = useSelector(
      (state: AppState) => state.home.availableReturnDates
  );
  const dates: any = useSelector((state: AppState) => state.home.dates);

  const fromAirport = useSelector((state: AppState) => state.home.fromAirport);
  const toAirport = useSelector((state: AppState) => state.home.toAirport);

  const adults = useSelector((state: AppState) => state.home.adults);
  const children = useSelector((state: AppState) => state.home.children);
  const infants = useSelector((state: AppState) => state.home.infants);

  const oneWay = useSelector((state: AppState) => state.home.oneWay);

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [isFromOpen, setIsFromOpen] = useState<boolean>(false);
  const [isToOpen, setIsToOpen] = useState<boolean>(false);
  const [isPassengerOpen, setIsPassengerOpen] = useState<boolean>(false);


  const handleFromIconClick = () => {
    setIsFromOpen(!isFromOpen);
  };

  const handleToIconClick = () => {
    setIsToOpen(!isToOpen);
  };

  const handlePassengerIconClick = () => {
    setIsPassengerOpen(!isPassengerOpen);
    if (!isPassengerOpen && window.innerWidth < 768) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  };

  const rangeDepartureDate = dates[0]
      ? moment(dates[0]).format("YYYY-MM-DD")
      : null;
  const rangeReturnDate = dates[1]
      ? moment(dates[1]).format("YYYY-MM-DD")
      : null;

  const [enabledDates, setEnabledDates] = useState("departure");

  const [isRangePickerVisible, setIsRangePickerVisible] =
      useState<boolean>(true);
  const refreshRangePicker = () => {
    setIsRangePickerVisible(false);
    setTimeout(() => {
      setIsRangePickerVisible(true);
    }, 10);
  };

  const dropdownMobileHeight =
      window.innerWidth < 768 ? window.innerHeight - 62 : undefined;

  useEffect(() => {
    dispatch(fetchAirports());
    dispatch(setDepartureFlight({}));

    dispatch(setReturnFlight({}))

    if (!history.location.state?.offerSelected) {
      dispatch(setDates([null, null]));
      history.replace("/", {
        state: undefined,
      });
    }

    if (fromAirport && toAirport) {
      dispatch(
          fetchAvailableDepartureDates({
            departureAirportCode: fromAirport,
            arrivalAirportCode: toAirport,
          })
      );
      dispatch(
          fetchAvailableReturnDates({
            departureAirportCode: toAirport,
            arrivalAirportCode: fromAirport,
          })
      );
    }

    document.addEventListener("click", handleWindowClick, true);
    // document.addEventListener("focus", handleWindowFocus, true);
    return () => {
      document.removeEventListener("click", handleWindowClick);
      // document.removeEventListener("focus", handleWindowFocus);
    };
  }, []);

  const handleWindowClick = (e: any) => {
    if (e.target.classList.contains("disabled-return-date")) {
      e.stopPropagation();
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (oneWay) {
      const rangePickerInputs = document.querySelectorAll(
          ".custom-date-range-picker .ant-picker-input>input"
      );
      if (rangePickerInputs.length) {
        rangePickerInputs.forEach((input, index) => {
          if (index === 1) {
            input.setAttribute("readonly", "true");
          }
        });
      }
    }
  }, [oneWay]);

  function disabledDate(current: any) {
    if (!fromAirport || !toAirport) {
      return true;
    }

    if (enabledDates === "departure") {
      const currentDate = new Date(
          current._d.getFullYear(),
          current._d.getMonth(),
          current._d.getDate()
      );
      for (let i = 0; i < departureDates.length; i++) {
        const item = DateUtils.getDateBy(departureDates[i]);
        if (currentDate.getTime() === item.getTime()) return false;
      }
      return true;
    } else {
      const currentDate = new Date(
          current._d.getFullYear(),
          current._d.getMonth(),
          current._d.getDate()
      );
      for (let i = 0; i < returnDates.length; i++) {
        const item = DateUtils.getDateBy(returnDates[i]);
        if (currentDate.getTime() === item.getTime()) return false;
      }
      return true;
    }
  }

  const scrollToTop = () => {
    window.scrollTo({top: 0, behavior: "smooth"});
  };

  const onFinish = () => {
    history.push({
      pathname: "/booking/flights",
      search: `?from=${fromAirport}&to=${toAirport}&departure=${rangeDepartureDate}${
          rangeReturnDate ? "&return=" + rangeReturnDate : ""
      }&adults=${adults}${children ? "&children=" + children : ""}${
          infants ? "&infants=" + infants : ""
      }`,
    });
  };

  const onFromChange = (item: any) => {
    if (item == null) return;
    dispatch(setFromAirport(item));
    if (item && toAirport) {
      dispatch(setDates([]));
      refreshRangePicker();
      dispatch(
          fetchAvailableDepartureDates({
            departureAirportCode: item,
            arrivalAirportCode: toAirport,
          })
      );
      dispatch(
          fetchAvailableReturnDates({
            departureAirportCode: toAirport,
            arrivalAirportCode: item,
          })
      );
    }
  };

  const onToChange = (item: any) => {
    if (item == null) return;
    dispatch(setToAirport(item));
    if (item && fromAirport) {
      dispatch(setDates([]));
      refreshRangePicker();
      dispatch(
          fetchAvailableDepartureDates({
            departureAirportCode: fromAirport,
            arrivalAirportCode: item,
          })
      );
      dispatch(
          fetchAvailableReturnDates({
            departureAirportCode: item,
            arrivalAirportCode: fromAirport,
          })
      );
    }
  };

  const onDateChange = (dates: any) => {
    dispatch(setDates(dates));
    if (oneWay) {
      if (dates.length && dates[0]) {
        setIsCalendarOpen(false);
        setTimeout(() => blurActiveElement(), 100);
      }
    }
  };

  const searchButtonDisabled = () => {
    if (!oneWay) {
      if (dates[1] == null) {
        return !dates[0] || !dates[1] || !fromAirport || !toAirport;
      }
    }
    return !dates[0] || !fromAirport || !toAirport;
  };

  const switchDestinations = () => {
    const currentFromAirport = fromAirport;
    const currentToAirport = toAirport;
    dispatch(setFromAirport(currentToAirport));
    dispatch(setToAirport(currentFromAirport));
    if (fromAirport && toAirport) {
      dispatch(
          fetchAvailableDepartureDates({
            departureAirportCode: toAirport,
            arrivalAirportCode: fromAirport,
          })
      );
      dispatch(
          fetchAvailableReturnDates({
            departureAirportCode: fromAirport,
            arrivalAirportCode: toAirport,
          })
      );
    } else {
      dispatch(clearAvailableDates());
    }
    dispatch(setDates([null, null]));
    dispatch(setOneWay(false));
  };

  const onOneWayButtonClick = () => {
    dispatch(setDates([dates[0], null]));
    setIsCalendarOpen(false);
    dispatch(setOneWay(true));
    blurActiveElement();
    document.body.classList.remove("overflow-hidden");
  };

  const onSaveButtonClick = () => {
    setIsCalendarOpen(false);
    if (!dates[1]) {
      dispatch(setOneWay(true));
    }
    blurActiveElement();
    document.body.classList.remove("overflow-hidden");
  };

  const onCancelButtonClick = () => {
    dispatch(setDates([null, null]));
    setIsCalendarOpen(false);
    dispatch(setOneWay(false));
    blurActiveElement();
    document.body.classList.remove("overflow-hidden");
  };

  const blurActiveElement = () => {
    if (document.activeElement instanceof HTMLElement)
      document.activeElement.blur();
  };

  const onOpenCalendar = (open: boolean) => {
    if (fromAirport && toAirport) {
      setIsCalendarOpen(open);

      if (open && window.innerWidth < 768) {
        document.body.classList.add("overflow-hidden");
      } else {
        document.body.classList.remove("overflow-hidden");
      }
    } else {
      setIsCalendarOpen(false);
      document.body.classList.remove("overflow-hidden");
    }
  };

  const onFocusCalendar = (e: any) => {
    const rangePickerInputs = document.querySelectorAll(
        ".custom-date-range-picker .ant-picker-input"
    );
    if (rangePickerInputs.length) {
      rangePickerInputs.forEach((input, index) => {
        const classes = input.classList;
        if (classes.contains("ant-picker-input-active")) {
          if (index === 0) {
            setEnabledDates("departure");
          } else {
            setEnabledDates("return");
          }
        }
      });
    }
  };

  const onAdultsDecrease = (e: any) => {
    if (adults - 1 + children <= 10 && adults + children >= 0) {
      const updatedAdults = adults - 1;
      dispatch(setAdults(updatedAdults));
      if (infants > updatedAdults) {
        dispatch(setInfants(updatedAdults));
      }
    }
  };

  const onAdultsIncrease = (e: any) => {
    if (adults + 1 + children <= 10 && adults + children >= 0) {
      dispatch(setAdults(adults + 1));
    }
  };

  const onChildrenIncrease = (e: any) => {
    if (adults + 1 + children <= 10 && adults + children >= 0) {
      dispatch(setChildren(children + 1));
    }
  };

  const onChildrenDecrease = (e: any) => {
    if (adults - 1 + children <= 10 && adults + children >= 0) {
      dispatch(setChildren(children - 1));
    }
  };

  const onInfantsIncrease = (e: any) => {
    if (infants + 1 <= adults) {
      dispatch(setInfants(infants + 1));
    }
  };

  const onInfantsDecrease = (e: any) => {
    if (infants - 1 <= adults) {
      dispatch(setInfants(infants - 1));
    }
  };

  const selectedBestOffer = (offer: any) => {
    dispatch(setFromAirport(offer.departureLocation.code));
    dispatch(setToAirport(offer.arrivalLocation.code));
    dispatch(
        fetchAvailableDepartureDates({
          departureAirportId: offer.departureLocation.code,
          arrivalAirportId: offer.arrivalLocation.code,
        })
    );
    dispatch(
        fetchAvailableReturnDates({
          departureAirportId: offer.arrivalLocation.code,
          arrivalAirportId: offer.departureLocation.code,
        })
    );
    onDateChange([moment(DateUtils.getDateBy(offer.dateOfDeparture)), null]);
    dispatch(setOneWay(true));
    setIsPassengerOpen(true);
    scrollToTop();
  };

  const onDropdownVisibleChange = (open: boolean) => {
    if (open && window.innerWidth < 768) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  };

  const onRadioInputChange = (e: any) => {
    dispatch(setOneWay(e.target.value));
    if (e.target.value) {
      dispatch(setDates([dates[0], null]));
    }
  };

  const handleLinkClick = (event: any, link: string) => {
    event.preventDefault();
    history.push(link);
  }

  const options = [
    {label: `${t("app.home.form.from.radio1", "Round trip")}`, value: false},
    {label: `${t("app.home.form.from.radio2", "One Way")}`, value: true},
  ];

  return (
      <div className="homepage-container">
        <div className="homepage-header">
          <div className="intro">
            <div className="introStart">
              <h1>Lorem Ipsum <b>dolor sit</b></h1>
            </div>
            <div className="introEnd">
              <p>Lorem ipsum dolor sit amet consectetur.
                <b>Faucibus dui viverra amet sem volutpat ipsum ut amet. </b></p>
              <Button>Learn More</Button>
            </div>
          </div>
          <div className="homepage-form">
            <div style={{display: "block"}}>
              <Radio.Group
                  options={options}
                  onChange={onRadioInputChange}
                  value={oneWay}
                  optionType="button"
                  buttonStyle="solid"
              />
              <div className="flex">
                <div className="test">
                  {/*<img src={Takeoff} alt="airplane icon"/>*/}
                  <div className="homepage-form-input">
                    <label htmlFor="from-input">
                      {t("app.home.form.from.label", "From")}:
                    </label>
                    <Select
                        showSearch={!isMobile ? true : false}
                        id="from-input"
                        bordered={false}
                        onChange={onFromChange}
                        optionLabelProp="displayValue"
                        open={isFromOpen}
                        onDropdownVisibleChange={(open) => setIsFromOpen(open)}
                        suffixIcon={<DownOutlined onClick={handleFromIconClick} style={{cursor: 'pointer'}}/>}
                        listHeight={dropdownMobileHeight}
                        className="homepage-form-select-input"
                        dropdownClassName="homepage-form-select-input"
                        virtual={false}
                        placeholder={
                          <span className="opacity-60">
                  {t("app.home.form.from.placeholder", "Choose")}
                </span>
                        }
                        value={airports.length ? fromAirport : ""}
                        filterOption={(input, option) =>
                            !!option?.displayValue
                                ?.toString()
                                .toLowerCase()
                                .includes(input.toLowerCase())
                        }
                    >
                      <div className="select-input-header">
                        <div className="select-input-header-title">
                          {t("app.home.form.from.mobile.header", "Leaving from")}
                        </div>
                        <CloseIcon/>
                      </div>
                      {airports.map((country: any) => (
                          <OptGroup
                              className="option-form-select-input"
                              key={country.id}
                              label={country.name}
                          >
                            {country.airports.map((airport: BookingBody) => (
                                <Select.Option
                                    id={airport.id}
                                    key={airport.id}
                                    value={airport.code}
                                    displayValue={airport.name}
                                    disabled={toAirport === airport.code}
                                    className={`airports-option ${
                                        toAirport === airport.code ? "opacity-60" : ""
                                    }`}
                                >
                                  <div className="airport">
                                    <img style={{width: '30px', marginRight: '10px'}} src={country.flagImageUrl}/>
                                    <span>{airport.name}</span>
                                    <span className="opacity-60 ml-4">{airport.code}</span>
                                  </div>
                                </Select.Option>
                            ))}
                          </OptGroup>
                      ))}
                    </Select>
                    <button
                        className="switch-destinations-button"
                        onClick={switchDestinations}
                    >
                      <RefreshIcon/>
                    </button>
                  </div>
                </div>

                <div className="test">
                  {/*<img src={Landing} alt="airplane icon"/>*/}
                  <div className="homepage-form-input">
                    <label htmlFor="from-input">
                      {t("app.home.form.to.label", "To")}:
                    </label>
                    <Select
                        showSearch={!isMobile ? true : false}
                        bordered={false}
                        onChange={onToChange}
                        suffixIcon={<DownOutlined onClick={handleToIconClick} style={{cursor: 'pointer'}}/>}
                        open={isToOpen}
                        onDropdownVisibleChange={(open) => setIsToOpen(open)}
                        optionLabelProp="displayValue"
                        className="homepage-form-select-input"
                        dropdownClassName="homepage-form-select-input"
                        virtual={false}
                        placeholder={
                          <span className="opacity-60">
                  {t("app.home.form.to.placeholder", "Choose")}
                </span>
                        }
                        value={airports.length ? toAirport : ""}
                        filterOption={(input, option) =>
                            !!option?.displayValue
                                ?.toString()
                                .toLowerCase()
                                .includes(input.toLowerCase())
                        }
                    >
                      <div className="select-input-header">
                        <div className="select-input-header-title">
                          {t("app.home.form.to.mobile.header", "Leaving to")}
                        </div>
                        <CloseIcon/>
                      </div>
                      {airports.map((country: any) => (
                          <OptGroup key={country.id} label={country.name}>
                            {country.airports.map((airport: BookingBody) => (
                                <Select.Option
                                    id={airport.id}
                                    key={airport.id}
                                    value={airport.code}
                                    displayValue={airport.name}
                                    disabled={fromAirport === airport.code}
                                    className={`airports-option ${
                                        fromAirport === airport.code ? "opacity-60" : ""
                                    }`}
                                >
                                  <div className="airport">
                                    <img style={{width: '30px', marginRight: '10px'}} src={country.flagImageUrl}/>
                                    <span>{airport.name}</span>
                                    <span className="opacity-60 ml-4">{airport.code}</span>
                                  </div>
                                </Select.Option>
                            ))}
                          </OptGroup>
                      ))}
                    </Select>
                  </div>
                </div>

                <div className="homepage-form-input-passenger">
                  <label htmlFor="passengers-input">
                    {t("app.home.form.passengers.label", "Passengers")}:
                  </label>
                  <Select
                      defaultOpen={true}
                      placeholder={
                        preparePassengersLabel({
                          adults,
                          children,
                          infants,
                          adultsSingularLabel: t(
                              "app.home.form.passengers.label.adults.singular"
                          ),
                          childrenSingularLabel: t(
                              "app.home.form.passengers.label.children.singular"
                          ),
                          babiesSingularLabel: t(
                              "app.home.form.passengers.label.babies.singular"
                          ),
                          adultsPluralLabel: t(
                              "app.home.form.passengers.label.adults.plural"
                          ),
                          childrenPluralLabel: t(
                              "app.home.form.passengers.label.children.plural"
                          ),
                          babiesPluralLabel: t(
                              "app.home.form.passengers.label.babies.plural"
                          ),
                        })}
                      suffixIcon={<PlusOutlined onClick={handlePassengerIconClick} style={{cursor: 'pointer'}}/>}
                      onDropdownVisibleChange={setIsPassengerOpen}
                      open={isPassengerOpen}
                      labelInValue={true}
                      className="homepage-form-select-input"
                      dropdownClassName="homepage-form-select-input"
                      dropdownRender={() => (
                          <HomepagePassengersDropdown
                              adults={adults}
                              adultsDisabled={adults < 2}
                              onAdultsIncrease={onAdultsIncrease}
                              onAdultsDecrease={onAdultsDecrease}
                              children={children}
                              childrenDisabled={children < 1}
                              onChildrenIncrease={onChildrenIncrease}
                              onChildrenDecrease={onChildrenDecrease}
                              infants={infants}
                              infantsDisabled={infants < 1}
                              onInfantsIncrease={onInfantsIncrease}
                              onInfantsDecrease={onInfantsDecrease}
                              onSavePassengerButtonClick={() => {
                                setIsPassengerOpen(false);
                                document.body.classList.remove("overflow-hidden");
                              }}
                              onCancel={() => {
                                setIsPassengerOpen(false);
                                document.body.classList.remove("overflow-hidden");
                              }}
                          />
                      )}
                  ></Select>
                </div>
              </div>

              <div className="flex">
                <div className="formItem dateRangeFormItem">
                  <Row className="d-flex">
                    <Col span={12} className="ant-form-item-label">
                      <label
                          className={classNames({
                            "opacity-60": !fromAirport || !toAirport,
                          })}
                      >
                        {t("app.home.form.departure.label", "Departure")}:
                      </label>
                    </Col>
                    <Col span={12} className="ant-form-item-label">
                      <label className={classNames({"opacity-60": !dates[0]})} style={{paddingLeft: "14px"}}>
                        {t("app.home.form.return.label", "Return")}:
                      </label>
                    </Col>
                  </Row>
                  {isRangePickerVisible &&
                      <RangePicker
                          key={departureDates[0]}
                          defaultPickerValue={[
                            (departureDates && departureDates.length > 0 ? moment(departureDates[0], "DD/MM/YYYY") : moment()),
                            (departureDates && departureDates.length > 0 ? moment(departureDates[0], "DD/MM/YYYY") : moment())
                          ]}
                          size="large"
                          value={dates.map((date: any) => moment.isMoment(date) ? date : null)}
                          bordered={false}
                          allowClear={false}
                          format="DD/MM/YYYY"
                          open={isCalendarOpen}
                          allowEmpty={[false, false]}
                          disabledDate={disabledDate}
                          onCalendarChange={onDateChange}
                          onFocus={onFocusCalendar}
                          onOpenChange={onOpenCalendar}
                          inputReadOnly={true}
                          placeholder={[
                            "Departure date",
                            oneWay
                                ? t("app.home.form.return.oneway", "One way")
                                : "Return date",
                          ]}
                          className={classNames("custom-date-range-picker", {
                            "one-way": oneWay,
                            disabled: !fromAirport || !toAirport,
                            "disabled-return-date": oneWay || !dates[0],
                          })}
                          dropdownClassName={classNames(
                              "custom-date-range-picker-dropdown",
                              {"one-way": oneWay}
                          )}
                          // onPanelChange={(a, b) => console.log("PANEL", a, b)}
                          // onClick={(e) => console.log(e)}
                          panelRender={(panel) => (
                              <RangePickerPanel
                                  panel={panel}
                                  departureDate={dates[0]}
                                  returnDate={dates[1]}
                                  activeInput={enabledDates}
                                  onOneWayButtonClick={onOneWayButtonClick}
                                  onSaveButtonClick={onSaveButtonClick}
                                  onCancelButtonClick={onCancelButtonClick}
                              />
                          )}
                      />
                  }
                </div>
                <div className="text-center">
                  <Button
                      htmlType="button"
                      onClick={onFinish}
                      disabled={searchButtonDisabled()}
                      className="button-primary homepage-form-search-button"
                  >
                    {t("app.home.form.search.button", "Search")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="homepage-offers">
          <div className="homepage-offers-title">
            <p className="homepage-view-destinations">
              {t("app.home.sections.offers.title", "Popular Destinations")}
            </p>
            <div>
              {/*<img src={Next}/>*/}
              <Link onClick={(e) => handleLinkClick(e, "/")}
                    to="#">{"View all destinations"}</Link>
            </div>
          </div>
          <div className="homepage-offers-offer">
            {offers.map((offer: any, index) => (
                <HomepageOfferCard
                    key={`homepage-offer-card-${index}`}
                    onClick={() => selectedBestOffer(offer)}
                    offer={offer}
                />
            ))}
          </div>
        </section>

        <Checkin/>
        <FrequentlyAskedQuestions/>

        <Footer/>

      </div>
  );
};

export default Homepage;