import React, {useState} from 'react'
import styles from "./FrequantlyAskedQuestions.module.scss";
import { Dropdown, Menu, Space } from "antd";
import {DownOutlined, PlusOutlined, UpOutlined} from "@ant-design/icons";


const FrequentlyAskedQuestions = () => {

  const [isDivOpen, setIsDivOpen] = useState(false);

  // const toggleDiv = () => {
  //   setIsDivOpen(!isDivOpen);
  // };

  const languageMenu = (
      <Menu className={styles.menuStyle}>
        <Menu.Item
            key="0"
            className={styles.menuItemStyle}
            // onClick={() => {toggleDiv()}}
        >
          <p onClick={() => {
            // toggleDiv();
          }}>1. What is the size and weight of hand luggage?
          </p>
          <PlusOutlined />
        </Menu.Item>
        {isDivOpen &&
            <div className={styles.collapsedQuestion}>
                <p>Lorem ipsum dolor sit amet consectetur. Egestas tempor nulla risus id nunc sed urna tortor.
                    Posuere fames ullamcorper in vestibulum tortor arcu nibh mauris. Tellus feugiat enim ut cras felis hendrerit sit aliquam sapien.
                    Condimentum viverra eget laoreet id non purus scelerisque. Ornare ornare placerat quis quam. Aenean est adipiscing nulla odio. Eu lectus donec
                    quam netus at id dictumst suspendisse. Arcu enim eu quis vestibulum blandit tellus enim eleifend natoque.
                    Tincidunt gravida sapien lectus vitae purus tortor quisque. Pulvinar nulla lorem bibendum accumsan a fermentum. Turpis cum cursus pellentesque dictum laoreet nullam gravida.
                    Purus tristique eu elit quam aliquam egestas arcu. Ut sit dolor sed quis. Gravida egestas vitae donec varius odio scelerisque. Tortor risus pellentesque sed nec. Et sapien maecenas ut tincidunt mauris ipsum scelerisque a enim.
                    <br></br>
                    Lorem ipsum dolor sit amet consectetur. Egestas tempor nulla risus id nunc sed urna tortor.

                    Posuere fames ullamcorper in vestibulum tortor arcu nibh mauris. Tellus feugiat enim ut cras felis hendrerit sit aliquam sapien.

                    Condimentum viverra eget laoreet id non purus scelerisque. Ornare ornare placerat quis quam. Aenean est adipiscing nulla odio. Eu lectus donec quam netus at id dictumst suspendisse. Arcu enim eu quis vestibulum blandit tellus enim eleifend natoque.

                    Tincidunt gravida sapien lectus vitae purus tortor quisque. Pulvinar nulla lorem bibendum accumsan a fermentum. Turpis cum cursus pellentesque dictum laoreet nullam gravida.

                    Purus tristique eu elit quam aliquam egestas arcu. Ut sit dolor sed quis. Gravida egestas vitae donec varius odio scelerisque. Tortor risus pellentesque sed nec. Et sapien maecenas ut tincidunt mauris ipsum scelerisque a enim.

                </p>
            </div>}
        <Menu.Item
            key="1"
            className={styles.menuItemStyle}>
          <p onClick={() => {
          }}>2. What is the size and weight of hand luggage?
          </p>
          <PlusOutlined />
        </Menu.Item>
        <Menu.Item
            key="2"
            className={styles.menuItemStyle}>
          <p onClick={() => {
          }}>3. What is the size and weight of hand luggage?
          </p>
          <PlusOutlined />
        </Menu.Item>
      </Menu>
  );

  return (
      <div className={styles.faqComponent}>
        <h1>Frequently Asked Questions</h1>
        <div className={styles.dropdowns}>
          <Dropdown
              overlay={languageMenu}
              trigger={['click']}>
            <a onClick={e => e.preventDefault()}>
              <Space className={styles.space}>
                Baggage operations
                <DownOutlined />
              </Space>
            </a>
          </Dropdown>
          <Dropdown
              overlay={languageMenu}
              trigger={['click']}>
            <a onClick={e => e.preventDefault()}>
              <Space className={styles.space}>
                Check in
                <DownOutlined />
              </Space>
            </a>
          </Dropdown>
          <Dropdown
              overlay={languageMenu}
              trigger={['click']}>
            <a onClick={e => e.preventDefault()}>
              <Space className={styles.space}>
                Ticket operations
                <DownOutlined />
              </Space>
            </a>
          </Dropdown>
          <Dropdown
              overlay={languageMenu}
              trigger={['click']}>
            <a onClick={e => e.preventDefault()}>
              <Space className={styles.space}>
                Refunds and Claims
                <DownOutlined />
              </Space>
            </a>
          </Dropdown>
        </div>
      </div>
  )
}

export default FrequentlyAskedQuestions;