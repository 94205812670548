import React from "react";
import styles from './Details.module.scss';
import AirplaneIcon from "../../assets/images/airplane.png";
import QrCode from "../../assets/images/qr-code.png";
import moment from "moment";
import {useTranslation} from "react-i18next";
import {PassengerType} from "../PassengerFormRow/PassengerFormRow";

interface Props {
  passenger: any;
}

const Passenger = ({passenger}: Props) => {
  const {t} = useTranslation();

  const type = passenger.ageCategory;
  const PassengerTitle: any = {
    [PassengerType.ADULT]: t("app.bookFlight.index.adult.lowercase", "Adult"),
    [PassengerType.CHILD]: t("app.bookFlight.index.child.lowercase", "Child"),
    [PassengerType.INFANT]: t("app.bookFlight.index.infant.lowercase", "Infant")
  }

  return (
      <div>
        <p style={{fontSize: "20px", fontWeight: 700, lineHeight: "30px", textTransform: "capitalize"}}>{t("app.bookFlight.overview.bookingInformation", "Passenger")}
          {PassengerTitle[type]} : {passenger.name} {passenger.surname}
        </p>
      </div>
  )
}

export default Passenger;
