import React from 'react';
import HomepageOfferCard from "../HomepageOfferCard";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store";
import {
    fetchAvailableDepartureDates,
    fetchAvailableReturnDates,
    setDates,
    setFromAirport,
    setOneWay,
    setToAirport
} from "../../store/home";
import moment from "moment";
import DateUtils from "../../types/utils/DateUtils";
import {useHistory} from "react-router";

const SideOffers = (props: any) => {

    const history = useHistory();
    const dispatch = useDispatch();
    const offers = useSelector((state: AppState) => state.offers.offers);

    const selectedBestOffer = (offer: any) => {
        dispatch(setFromAirport(offer.departureLocation.code));
        dispatch(setToAirport(offer.arrivalLocation.code));
        dispatch(fetchAvailableDepartureDates({departureAirportId: offer.departureLocation.code, arrivalAirportId: offer.arrivalLocation.code}));
        dispatch(fetchAvailableReturnDates({departureAirportId: offer.arrivalLocation.code, arrivalAirportId: offer.departureLocation.code}));
        dispatch(setDates([moment(DateUtils.getDateBy(offer.dateOfDeparture)), null]));
        dispatch(setOneWay(true));
        history.push("/", {
            offerSelected: true
        });
    }

    return (
        <React.Fragment>
            {offers.map((offer: any, index) =>
                <HomepageOfferCard small className="mb-4" key={`homepage-offer-card-${index}`} onClick={() => selectedBestOffer(offer)} offer={offer}/>)}
        </React.Fragment>
    )

}


export default SideOffers;
