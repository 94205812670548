import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Wing from "../../assets/images/plane-wing.png";
import ArrDep from "../../assets/images/dep-arr.png";
import Inside from "../../assets/images/plane-inside.png";


const About = () =>{


    const scrollToWhoAreWe = () => {
        window.scrollTo({
            top: 270,
            behavior: "smooth"
        });
    }

    const scrollToDestinations = () => {
        window.scrollTo({
            top: 810,
            behavior: "smooth"
        });
    }

    return(
        <div>
            <div className="about-us">
                <h2>About Us</h2>
                {/*<p>Nunc commodo porttitor ipsum sit amet venenatis. Aliquam libero quam, blandit ac placerat eget,*/}
                {/*    laoreet vitae quam. Aliquam ut nunc vel sem consectetur pellentesque.*/}
                {/*    Pellentesque mollis efficitur euismod.</p>*/}
                <div className="about-position">
                    <div className="content">
                        <p onClick={scrollToWhoAreWe}>Who are we?</p>
                        {/*<p onClick={scrollToDestinations}>Destinations</p>*/}
                    </div>
                    <div className="about-us-content">
                        <h3>Who are we?</h3>
                        <p> “Airtiketa L.L.C” si kompani u themelua në vitin 2015 dhe menaxhohet nga stafi me përvojë mbi 20 vjet. Duke menduar gjithmonë që t'i shpërndajmë kilometrat që i ndajnë bashkatdhetarët tanë nga shtëpia e tyre, prioriteti ynë si kompani është të sjellim më të mirën te klientët tanë.
                            Përveç sigurisë, komoditetit dhe çmimeve më të mira në treg, kompania jonë është dëshmuar edhe për korrektësinë ndaj të gjithë atyre që kanë besuar tek ne. Të ofrosh më të mirën duhet fillimisht të jesh ndër më të mirët, dhe kjo sigurisht falë përkushtimit të stafit profesional që është në dispozicion 24/7, duke qenë aty për ju në çdo kohë!
                            Për qasje më të lehtë, ne kemi krijuar sistemin tonë online të rezervimit për t'iu kursyer kohë dhe për t'i lehtësuar procedurat. Eja në shtëpinë tënde! Më sigurt dhe më lirë se kurrë më parë! Lërini ëndrrat të udhëtojnë!</p>
                        <img src={Wing}/>
                        {/*<h3>Destinations</h3>*/}
                        {/*<p> The first flights offererd will be: </p>*/}
                        {/*<div className="destinations">*/}
                        {/*    <b>• Pristina - Basel - Pristina</b> direct flights (every day in the morning and in the afternoon up to 12 per week) <br/>*/}
                        {/*    <b>• Pristina - Düsseldorf - Pristina</b> direct flights (every day) <br/>*/}
                        {/*    <b>• Pristina - Stuttgart - Pristina</b> direct flights (every day) <br/>*/}
                        {/*    <b>• Pristina - Munich - Pristina</b> direct flights (up to 5 times a week)*/}
                        {/*</div>*/}
                        {/*<img src={ArrDep}/>*/}
                    </div>
                </div>
            </div>
            <Footer />
        </div>

    )
}

export default About;
