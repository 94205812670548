import React, {useState} from 'react'
import styles from "./Checkin.module.scss";
import {Divider} from "antd/es";
import Star from "../../assets/images/Group 2.svg"
import Ticket from "../../assets/images/Group 3.svg"
import Seat from "../../assets/images/Group 4.svg"
import Headphones from "../../assets/images/Group 5.svg"


const Checkin = () => {
  return (
      <div className={styles.benefits}>
        <h1>Benefits of booking with us.</h1>
        <div className={styles.benefitsContent}>
          <div className={styles.benefitsRow}>
          <img src={Star}/>
          <div className={styles.benefitsText}>
            <h3>Simplified Booking Experience</h3>
            <p>Feel the flexibility and simplicity throughout your booking process</p>
          </div>
        </div>
          <div className={styles.benefitsRow}>
            <img src={Seat}/>
            <div className={styles.benefitsText}>
              <h3>Seat Reservation</h3>
              <p>Feel the flexibility and simplicity throughout your booking process</p>
            </div>
          </div>
          <div className={styles.benefitsRow}>
            <img src={Ticket}/>
            <div className={styles.benefitsText}>
              <h3>Exclusive Offer Everyday</h3>
              <p>Feel the flexibility and simplicity throughout your booking process</p>
            </div>
          </div>
          <div className={styles.benefitsRow}>
            <img src={Headphones}/>
            <div className={styles.benefitsText}>
              <h3>Fast Customer Support</h3>
              <p>Feel the flexibility and simplicity throughout your booking process</p>
            </div>
          </div>
        </div>
      </div>
  )
}

export default Checkin;