import React, {useEffect, useState} from 'react';
import styles from './Footer.module.scss';
import {Col, Divider, Row} from 'antd';
import {useHistory} from "react-router";
import {useTranslation} from "react-i18next";
import {FacebookFilled, InstagramFilled, LinkedinFilled, TwitterSquareFilled} from "@ant-design/icons";
import Logo from "../../assets/images/beMyTicket.png";
import {Link} from "react-router-dom";


const Footer = (props: any) => {

    const history = useHistory();
    const {t} = useTranslation();

    return (
        <div className={styles.footer}>
            <div style={{display: "flex", justifyContent: "space-between"}}>
                <div className="footerContent">
                    <img width={210} src={Logo} />
                    <p style={{marginTop: "35px"}}>Lorem ipsum dolor sit amet consectetur. Risus imperdiet et venenatis pretium sed amet vel.</p>
                    <div style={
                        {
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100px",
                            fontSize: "21px",
                            color: "white"
                        }}>
                        <FacebookFilled/>
                        <InstagramFilled />
                        <LinkedinFilled />
                    </div>
                </div>
                <div className="footerPadding">
                    <ul>
                        <li><h3><b>Book & Manage</b></h3></li>
                        <Divider />
                        <li>Book a flight</li>
                        <li>Check-in</li>
                        <li>Manage your booking</li>
                        <li>Additional services</li>
                        <li>Group bookings</li>
                        <li>Car Hire/</li>
                        <li>Transfer Hotel</li>
                    </ul>
                </div>
                <div className="footerPadding">
                    <ul>
                        <li><h3><b>Explore</b></h3></li>
                        <Divider />
                        <li>Deals and offers</li>
                        <li>Discover possibilities</li>
                        <li>Campaigns</li>
                        <li>AirportFlight plan</li>
                    </ul>
                </div>
                <div>
                    <ul>
                        <li><h3><b>Support Center</b></h3></li>
                        <Divider />
                        <li>Baggage allowance</li>
                        <li>FAQRules & regulations</li>
                        <li>Contact</li>
                    </ul>
                </div>
            </div>
            <Divider />
            <p style={{fontSize: '10px'}}>All rights reserved Aircraft 2022</p>
        </div>
    )
}
export default Footer;
