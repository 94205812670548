import React, { useEffect, useState } from "react";
import "./Payment.scss";
import { Button } from "antd";
import PaymentForm from "../../../components/PaymentForm/PaymentForm";
import { useSelector } from "react-redux";
import { AppState } from "../../../store";
import { getQueryParameterByName } from "../../../utils";
import { useHistory } from "react-router-dom";
import { PaymentService } from "../../../actions";
import Spinner from "../../../components/Spinner/Spinner";
import { useTranslation } from "react-i18next";

const Payment = (props: any) => {
  const history = useHistory();
  const { t } = useTranslation();

  const [instance, setInstance] = useState<any>(null);
  const passengers = useSelector(
    (state: AppState) => state.passengers.passengers
  );

  const price = useSelector(
    (state: AppState) => state.selectedFlights.pricePreview
  );

  const accountInfoState = useSelector(
    (state: AppState) => state.accountInfo.accountInfo
  );

  const departureAirport = getQueryParameterByName("from");
  const arrivalAirport = getQueryParameterByName("to");
  const departureDate = getQueryParameterByName("departure");
  const returnDate = getQueryParameterByName("return");
  const adults = getQueryParameterByName("adults");
  const children = getQueryParameterByName("children");
  const infants = getQueryParameterByName("infants");

  let timeout: any = null;
  useEffect(() => {
    if (!passengers.length) {
      history.push({
        pathname: "/booking/flights",
        search: `?from=${departureAirport}&to=${arrivalAirport}&departure=${departureDate}&return=${returnDate}&adults=${adults}&children=${children}&infants=${infants}`,
      });
    }
  }, []);

  const onInstantiation = (error: any, instance: any) => {
    setInstance(instance);
  };

  const nextStep = () => {
    if (instance) {
      clearTimeout(timeout);
      instance.requestPaymentMethod((error: any, payload: any) => {
        if (!error && payload && passengers.length) {
          PaymentService.checkout({
            travelers: passengers,
            contactNumber: passengers[0].phoneNumber,
            fullName: passengers[0].name + "" + passengers[0].surname,
            email: passengers[0].email,
          })
            .then((res) => {
              console.log(res);
              history.push("/booking/details/" + res.id);
            })
            .catch((err) => {
              console.error(err);
              alert("Error");
            });
        }
      });
    }
  };

  return (
    <div className="payment-container">
      <div style={{ overflow: "hidden" }} className="flights-padding-container">
        <div className="flights-page-header">
          <div className="title">Payment</div>
        </div>
        {!instance && (
          <div className="text-center mt-5">
            <Spinner />
          </div>
        )}
        <div style={{ visibility: instance ? "visible" : "hidden" }}>
          <PaymentForm onInstantiation={onInstantiation} />
        </div>
      </div>
      {/*<div className="flights-footer">*/}
      {/*    <div className="flights-footer-price">*/}
      {/*        Grand Total: <span className="price-text">{price} EUR</span>*/}
      {/*    </div>*/}
      {/*    <div className="footer-buttons">*/}
      {/*        <Button className="button-text-primary mr-0" htmlType="submit" onClick={() => history.push("/")}>New Search</Button>*/}
      {/*        <Button className="button-primary" htmlType="button" onClick={nextStep}>Finish Payment</Button>*/}
      {/*    </div>*/}
      {/*</div>*/}
      <div className="nav-bar">
        <div className="grand-price">
          {t("app.bookFlight.footer.grandTotal", "Grand total")}:{" "}
          <span>{price}</span>
        </div>
        <div className="nav-buttons">
          <Button
            className="button-text-primary mr-0"
            htmlType="submit"
            onClick={() => history.push("/")}
          >
            {t("app.bookFlight.footer.newSearch", "New search")}
          </Button>
          <Button
            className="button-primary"
            htmlType="button"
            onClick={nextStep}
          >
            {t("app.bookFlight.footer.continue", "Finish Payment")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Payment;
