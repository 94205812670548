import React, {useEffect, useState} from "react";
import "./Flights.scss";
import {getQueryParameterByName} from "../../../utils";
import SelectFlight from "../../../components/SelectFlight/SelectFlight";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../store";
import {ReactComponent as AirplaneIcon} from "../../../assets/images/airplane.svg";
import {
  fetchPricePreview,
  setDepartureFlight,
  setReturnFlight,
} from "../../../store/selectedFlights";
import FlightPreview from "../../../components/FlightPreview/FlightPreview";
import {fetchDailyFlights, setDailyFlights,} from "../../../store/daliyFlights";
import {setWeeklyFlights} from "../../../store/weeklyFlights";
import moment from "moment";
import {useHistory} from "react-router-dom";
import {ReactComponent as Arrow} from "../../../assets/images/right-arrow.svg";
import {fetchAirports} from "../../../store/countries";
import {useTranslation} from "react-i18next";
import BookFlightFooter from "../BookFlightFooter";

enum FLightSteps {
  SelectDeparture = "SelectDeparture",
  DeparturePreview = "DeparturePreview",
  SelectReturn = "SelectReturn",
  FlightsPreview = "FlightsPreview",
}

interface Props {
  onRefreshTimeout?: any;
}

const Flights = ({onRefreshTimeout}: Props) => {
  const dispatch = useDispatch();
  const history: any = useHistory();
  const {t} = useTranslation();

  const countries = useSelector((state: AppState) => state.countries.countries);
  const airports = countries.reduce(
      (acc: any, val: any) => acc.concat(val.airports),
      [] as any
  );

  const [currentStep, setCurrentStep] = useState<FLightSteps>(
      FLightSteps.SelectDeparture
  );

  const departureFlight = useSelector(
      (state: AppState) => state.selectedFlights.departureFlight
  );
  const returnFlight = useSelector(
      (state: AppState) => state.selectedFlights.returnFlight
  );

  const [dateOfDeparture, setDateOfDeparture] = useState<string>("");

  const price = useSelector(
      (state: AppState) => state.selectedFlights.pricePreview
  );

  const StepTitles = {
    SelectDeparture: t(
        "app.bookFlight.flights.steps.selectDeparture.title",
        "Departure:"
    ),
    SelectReturn: t(
        "app.bookFlight.flights.steps.selectReturn.title",
        "Return:"
    ),
    DeparturePreview: t(
        "app.bookFlight.flights.steps.selectedFlights.title",
        "Selected Flights"
    ),
    FlightsPreview: t(
        "app.bookFlight.flights.steps.selectedFlights.title",
        "Selected Flights"
    ),
  };

  const departureAirport = getQueryParameterByName("from");
  const arrivalAirport = getQueryParameterByName("to");
  const departureDate = getQueryParameterByName("departure");
  const returnDate = getQueryParameterByName("return");
  const adults = getQueryParameterByName("adults");
  const children = getQueryParameterByName("children");
  const infants = getQueryParameterByName("infants");
  const source = getQueryParameterByName("source");
  const language = getQueryParameterByName("language");

  const numberAdults = adults ? parseInt(adults) : 0;
  const numberChildren = children ? parseInt(children) : 0;
  const numberInfants = infants ? parseInt(infants) : 0;
  const totalPassengers = numberAdults + numberChildren + numberInfants;

  let timeout: any = null;

  useEffect(() => {
    dispatch(fetchAirports());

    if (history.location?.state?.backFromPassengers && returnDate) {
      setCurrentStep(FLightSteps.FlightsPreview);
      history.replace(history.location.pathname + history.location.search, {});
    } else if (history.location?.state?.backFromPassengers && !returnDate) {
      setCurrentStep(FLightSteps.DeparturePreview);
      history.replace(history.location.pathname + history.location.search, {});
    }
  }, []);


  useEffect(() => {
    if (departureFlight?.id && returnDate) {
      const priceParams = {
        numberOfAdults: adults || 0,
        numberOfChildren: children || 0,
        numberOfInfants: infants || 0,
        departureFlightId: departureFlight.id,
        hasReturnFlight: true,
        returnFlightId: returnFlight?.id,
      };
      dispatch(fetchPricePreview(priceParams));
      return;
    }
    if (departureFlight?.id && departureAirport && departureDate && !returnDate) {
      const priceParams = {
        numberOfAdults: adults || 0,
        numberOfChildren: children || 0,
        numberOfInfants: infants || 0,
        departureFlightId: departureFlight.id,
        hasReturnFlight: false,
        returnFlightId: returnFlight?.id,
      };
      dispatch(fetchPricePreview(priceParams));
    }
  }, [departureFlight, returnFlight]);

  const departureAirportName = airports.find(
      (airport: any) => airport.id == departureAirport
  )?.code;
  const arrivalAirportName = airports.find(
      (airport: any) => airport.id == arrivalAirport
  )?.code;

  const onDepartureFlightSelected = (flight: any) => {
    dispatch(setDepartureFlight({...flight, dateOfDeparture}));
    dispatch(setReturnFlight({}))
    const params = {
      dateOfDeparture: moment(dateOfDeparture, "DD/MM/YYYY").format(
          "DD/MM/YYYY"
      ),
      departureAirportCode: departureAirport,
      arrivalAirportCode: arrivalAirport,

    };

    dispatch(fetchDailyFlights(params));
    setCurrentStep(FLightSteps.DeparturePreview);
  };

  const onReturnFlightSelected = (flight: any) => {
    console.log(flight);

    const params = {
      dateOfDeparture: moment(dateOfDeparture, "DD/MM/YYYY").format(
          "DD/MM/YYYY"
      ),
      departureAirportCode: departureAirport,
      arrivalAirportCode: arrivalAirport,
      hasReturnFlight: true,
    };

    dispatch(fetchDailyFlights(params));

    dispatch(setReturnFlight({...flight, dateOfDeparture}));
    setCurrentStep(FLightSteps.FlightsPreview);
  };

  const nextStep = () => {
    if (currentStep === FLightSteps.DeparturePreview && returnDate) {
      dispatch(setWeeklyFlights([]));
      dispatch(setDailyFlights({flights: [], arrival: {}, departure: {}}));
      setCurrentStep(FLightSteps.SelectReturn);
    } else {
      // onRefreshTimeout();
      clearTimeout(timeout);
      history.push({
        pathname: "/booking/passengers",
        search: `?from=${departureAirport}&to=${arrivalAirport}&departure=${departureDate}${
            returnDate ? "&return=" + returnDate : ""
        }&adults=${adults}${children ? "&children=" + children : ""}${
            infants ? "&infants=" + infants : ""
        }${source ? "&source=" + source : ""}${language ? "&language=" + language : ""}`,
      });
    }
  };

  const onGoBackClick = () => {
    if (currentStep === FLightSteps.SelectDeparture) {
      history.push("/");
    } else if (currentStep === FLightSteps.DeparturePreview) {
      setCurrentStep(FLightSteps.SelectDeparture);
    } else if (currentStep === FLightSteps.SelectReturn) {
      setCurrentStep(FLightSteps.SelectDeparture);
    } else if (currentStep === FLightSteps.FlightsPreview) {
      setCurrentStep(FLightSteps.SelectReturn);
    }
  };

  const onDateOfDepartureSelected = (dateOfDeparture: string) => {
    setDateOfDeparture(dateOfDeparture);
  };

  return (
      <React.Fragment>
        <div className="flights-container">
          <div className="flights-padding-container">
            <div className="flights-page-back" >
              <div className="flights-back-arrow" onClick={onGoBackClick}>
                <Arrow/>
                {t("app.bookFlight.header.goBack", "Go back")}
              </div>
            </div>
            <div className="flights-page-header">
              <div className="title">{StepTitles[currentStep]}</div>
              <div>
              {currentStep === FLightSteps.SelectDeparture && (
                  <div className="subtitle">
                    {departureAirport} <AirplaneIcon/> {arrivalAirport}
                  </div>
              )}

              {currentStep === FLightSteps.SelectReturn && (
                  <div className="subtitle">
                    {arrivalAirport} <AirplaneIcon/> {departureAirport}
                  </div>
              )}
              </div>
            </div>
            {currentStep === FLightSteps.SelectDeparture && (
                <SelectFlight
                    totalPassengers={totalPassengers}
                    departureDate={departureDate}
                    departureAirport={departureAirport}
                    arrivalAirport={arrivalAirport}
                    onDateSelected={onDateOfDepartureSelected}
                    onFlightSelected={onDepartureFlightSelected}
                />
            )}

            {currentStep === FLightSteps.DeparturePreview && (
                <FlightPreview flight={departureFlight}/>
            )}

            {currentStep === FLightSteps.SelectReturn && (
                <SelectFlight
                    type="RETURN_FLIGHT"
                    totalPassengers={totalPassengers}
                    earliestDateAllowed={departureFlight.dateOfDeparture}
                    departureDate={returnDate}
                    departureAirport={arrivalAirport}
                    arrivalAirport={departureAirport}
                    onDateSelected={onDateOfDepartureSelected}
                    onFlightSelected={onReturnFlightSelected}
                />
            )}

            {currentStep === FLightSteps.FlightsPreview && (
                <>
                  {departureFlight && (
                      <FlightPreview className="mb-4" flight={departureFlight}/>
                  )}
                  {returnFlight && (
                      <FlightPreview isReturnFlight flight={returnFlight}/>
                  )}
                </>
            )}
          </div>
        </div>
        <div/>

        {(currentStep === FLightSteps.DeparturePreview ||
            currentStep === FLightSteps.FlightsPreview) && (
            <BookFlightFooter continue={nextStep}/>
        )}
      </React.Fragment>
  );
};

export default Flights;
