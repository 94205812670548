import React from 'react'
import styles from "./FlightRow.module.scss";
import moment from "moment";
import classNames from "classnames";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {AppState} from "../../store";
import {ReactComponent as AirplaneWhiteBackground}  from "../../assets/images/airplane-white-background.svg";
import {ReactComponent as Point} from "../../assets/images/point.svg";
import { Button } from "antd";
import Luggage  from "../../assets/images/fluent_luggage-28-filled.svg";
import Bagpack from "../../assets/images/mdi_bag-personal-outline.svg";
import Bag from "../../assets/images/mdi_bag-checked.svg";
import Ticket from "../../assets/images/icon-park-outline_ticket.svg";
import Airplane from "../../assets/images/ion_airplane.svg";

interface Props {
    flight: any;
    arrival: any;
    departure: any;
    departureDate?: any;
    className?: string;
    onClick: any;
    disabled: boolean;
    isBefore?: boolean | false;
}

const FlightRow = (props: Props) => {

    const {t} = useTranslation();
    const {flight, departure, arrival, disabled, className, onClick, departureDate} = props;
    const departureFlight = useSelector(
        (state: AppState) => state.selectedFlights.departureFlight
    );

    const currencyMismatch = () => {
        if (departureFlight?.id && flight?.id !== departureFlight?.id) {
            return departureFlight?.lowestPriceWithTax.slice(-1) !== flight?.lowestPriceWithTax.slice(-1)
        }
        return false;
    }

    const basicFlightInfo = () => {
        return (
            <div className={styles.basicInfo}>
                <p>What is included in</p>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <h1>Basic Flight</h1>
                    <h2>{flight.lowestPriceWithTax}</h2>
                </div>
                <div className={styles.benefits}>
                    <div><img src={Airplane}/><p>Fare + taxes</p></div>
                    <div><img src={Luggage}/><p>Personal item</p></div>
                    <div><img src={Ticket}/><p>Priority boarding</p></div>
                    <div><img src={Bagpack}/><p>Priority boarding</p></div>
                    <div><img src={Bag}/><p>Checked bag 20kg</p></div>
                </div>
            </div>
        )
    }
    const classes = classNames(styles.flightRow, className, {[styles.disabled]: disabled || currencyMismatch() || props.isBefore});
    const midnight = moment().startOf('day');
    // Check if time of arrival is after midnight
    const isAfterMidnight = moment(flight.timeOfArrival, "hh:mm:ss").isAfter(midnight);

// If the time of arrival is after midnight, update the date
    const adjustedDepartureDate = isAfterMidnight
        ? moment(departureDate, "DD/MM/YYYY").add(1, 'day').format("dddd, DD MMMM")
        : moment(departureDate, "DD/MM/YYYY").format("dddd, DD MMMM");

    return (
        <>
            <div className={classes}>
                {disabled && <div
                    className={styles.sold}>{t("app.bookFlight.flights.flightRow.soldOut", "Sold out")}</div>}
                {currencyMismatch() && !disabled && <div
                    className={styles.sold}>{t("app.bookFlight.flights.flightRow.currencyMismatch", "Currency mismatch")}</div>}
                {props.isBefore && <div className={styles.sold}>Date is before departure date</div>}

                <div className={styles.infoContainer}>
                    <div className={styles.flightDetails}>
                        <div className={styles.flightInfo}>
                            <div className={styles.date}>
                                {departureDate ? moment(departureDate, "DD/MM/YYYY").format("dddd, DD MMMM") : ''}
                            </div>
                            <div className={styles.time}>
                                {moment(flight.timeOfDeparture, "hh:mm:ss").format("HH:mm")}
                            </div>
                            <div className={styles.airport}>{departure.name}</div>
                        </div>
                            <Point className={styles.point}/>
                            <div className={styles.horizontal}>
                                <AirplaneWhiteBackground/>
                                <p className={styles.flightTime}>Flight time: {flight.flightDuration}</p>
                            </div>
                            <Point className={styles.point}/>
                        <div className={styles.flightInfo}>
                            <div className={styles.date}>
                                {departureDate ? adjustedDepartureDate : ''}
                            </div>
                            <div className={styles.time}>
                                {moment(flight.timeOfArrival, "hh:mm:ss").format("HH:mm")}
                            </div>
                            <div className={styles.airport}>{arrival.name}</div>
                        </div>
                    </div>

                    <div className={styles.selection}>
                        <div>
                            <h3>From</h3>
                            <h1>{flight.lowestPriceWithTax}</h1>
                            <p>Per passenger</p>
                        </div>
                        <Button className={styles.select} onClick={!currencyMismatch() && onClick}>Select</Button>
                    </div>
                </div>
            </div>
            {basicFlightInfo()}
        </>
    )
}

export default FlightRow;