import {createSlice, Dispatch} from '@reduxjs/toolkit';
import {AuthService, CustomersService} from "../actions";
import {toast} from "react-hot-toast";
import {parseJwt} from "../utils";

enum AuthenticationDrawerContent {
  Login = "login",
  Register = "register",
  ForgotPassword = 'forgot-password'

}

export const setLoginDrawer = (body: any) => async (dispatch: Dispatch) => {
  dispatch(loginDrawer(body));
}

export const setDrawerContent = (body: any) => async (dispatch: Dispatch) => {
  dispatch(drawerContent(body));
}

export const logout = () => async (dispatch: Dispatch) => {
  dispatch(logoutUser());
  toast.success("You are logged out!");
}

export const auth = (body: any, isFromRegister?: boolean, onSuccess?: () => void): any => async (dispatch: Dispatch) => {
  dispatch(setLoading(true))
  try {
    const response = await AuthService.login(body);

    const jwtTokenData = parseJwt(response.access_token);
    const authorities = jwtTokenData?.authorities;
    if (authorities && authorities.length) {
      if (authorities.includes("ROLE_ADMIN") || authorities.includes("ROLE_AGENT")) {
        // toast.error("You are not allowed to log in");
        dispatch(setErrorMsg('You are not allowed to log in'))
        dispatch(setLoading(false))
        return;
      }
    }

    if (!isFromRegister) {
      toast.success("You are logged in!");
    }
    dispatch(loginUser(response));
    dispatch(loginDrawer(false));
    dispatch(setLoading(false))
    dispatch(setErrorMsg(''))
    onSuccess && onSuccess();
    console.log(response);
  } catch (err) {
    // dispatch(setErrorMsg(err.data.error_description))
    dispatch(setLoading(false))
    // console.log(err);
  }
}

export const forgotPassword = (email: any, onSuccess?: () => void): any => async (dispatch: Dispatch) => {
  dispatch(setLoading(true))
  try {
    const response = await AuthService.forgotPassword(email);
    toast.success("Successfully!");
    onSuccess && onSuccess();
    dispatch(setLoading(false))
    return response;
  } catch (err) {
    dispatch(setLoading(false))
    console.log(err);
    throw err;
  }
}

export const resetAccountPassword = (body: any,key:string,onSuccess?: () => void): any => async (dispatch: Dispatch) => {
  dispatch(setLoading(true))
  try {
    const response = await AuthService.resetPassword(body,key);
    toast.success("You changed password successfully!");
    onSuccess && onSuccess();
    dispatch(setLoading(false))
    return response;
  } catch (err) {
    dispatch(setLoading(false))
    console.log(err);
    throw err;
  }
}

export const refreshAuth = (body: any): any => async (dispatch: Dispatch) => {
  try {
    const responseRfsh = await AuthService.rfshToken(body);
    console.log(responseRfsh);
    dispatch(reAuth(responseRfsh));
    return responseRfsh;
  } catch (err) {
    console.log(err);
    throw err;
  }
}

export const register = (body: any, onSuccess: () => void) => async (dispatch: Dispatch) => {
  try {
    const response = await CustomersService.createCustomer(body);
    dispatch(auth(body, true));
    toast.success("You are registered!");
    // dispatch(drawerContent(AuthenticationDrawerContent.Login));
    onSuccess();
  }
  catch (err) {
    // if (err.data.message.includes('User already exists')) {
    //   dispatch(forgotPasswordModal(true));
    //   console.log('open modal')
    // }
    // console.log(err);
    // dispatch(setLoading(false))
  }
  // dispatch(registerUser(response));
}

interface LoginSlice {
  isDrawerOpen: boolean;
  drawerContent: AuthenticationDrawerContent;
  forgotPasswordModal: boolean,
  userFullName: string | null;
  userEmail: string | null;
  accessToken: string | null;
  refreshToken: string | null;
  loading: boolean,
  errorMsg:string,
}

// interface CreateCustomerSlice {
//     success: boolean;
//     error: string;
//     loading: boolean;
// }

const initialState: LoginSlice = {
  isDrawerOpen: false,
  drawerContent: AuthenticationDrawerContent.Login,
  accessToken: localStorage.getItem("pr_jet_access_token"),
  refreshToken: localStorage.getItem("pr_jet_refresh_token"),
  userFullName: localStorage.getItem("pr_jet_user_name"),
  userEmail: localStorage.getItem("pr_jet_user_email"),
  forgotPasswordModal: false,
  loading: false,
  errorMsg:''

}

const stateSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    loginDrawer: (state, action) => {
      state.isDrawerOpen = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setErrorMsg: (state, action) => {
      state.errorMsg = action.payload
    },
    loginUser: (state, action) => {
      const accessToken = action.payload.access_token;
      const refreshToken = action.payload.refresh_token;
      const name = action.payload.fullName;
      const email = action.payload.email;

      if (accessToken) localStorage.setItem("pr_jet_access_token", accessToken);
      if (refreshToken) localStorage.setItem("pr_jet_refresh_token", refreshToken);
      if (name) localStorage.setItem("pr_jet_user_name", name);
      if (email) localStorage.setItem("pr_jet_user_email", email);

      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
      state.userFullName = name;
      state.userEmail = email;
    },
    reAuth: (state, action) => {
      const accessToken = action.payload.access_token;
      const refreshToken = action.payload.refresh_token;
      const name = action.payload.fullName;
      const email = action.payload.email;

      if (accessToken) localStorage.setItem("pr_jet_access_token", accessToken);
      if (refreshToken) localStorage.setItem("pr_jet_refresh_token", refreshToken);
      if (name) localStorage.setItem("pr_jet_user_name", name);
      if (email) localStorage.setItem("pr_jet_user_email", email);

      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
      state.userFullName = name;
      state.userEmail = email;
    },
    logoutUser: (state) => {
      localStorage.removeItem("pr_jet_access_token");
      localStorage.removeItem("pr_jet_refresh_token");
      localStorage.removeItem("pr_jet_user_name");
      localStorage.removeItem("pr_jet_user_email");
      state.accessToken = null
      state.refreshToken = null
      state.userFullName = null
      state.userEmail = null
    },
    drawerContent: (state, action) => {
      state.drawerContent = action.payload
    },
    forgotPasswordModal: (state, action) => {
      state.forgotPasswordModal = action.payload
    },
  }
});

export const {
  loginDrawer,
  loginUser,
  logoutUser,
  drawerContent,
  forgotPasswordModal,
  reAuth,
  setLoading,
  setErrorMsg
} = stateSlice.actions;

export default stateSlice.reducer;
