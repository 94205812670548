import React from 'react'
import styles from "./FlightPreview.module.scss";
import moment from "moment";
import classNames from "classnames";
import {ReactComponent as AirplaneIcon} from "../../assets/images/airplane-grey-background.svg";
import {useSelector} from "react-redux";
import {AppState} from "../../store";
import {useTranslation} from "react-i18next";
import {ReactComponent as Point} from "../../assets/images/point.svg"
import {ReactComponent as Airplane} from "../../assets/images/airplane.svg"

interface Props {
  flight: any;
  isReturnFlight?: boolean;
  className?: string;
}

const FlightPreview = (props: Props) => {

  const {t} = useTranslation();

  const {flight, isReturnFlight, className} = props;

  const formatPrice = () => {
    let price = flight.lowestPriceWithTax.split(".", 1);
    let symbol = flight.lowestPriceWithTax.split(".00 ", 2)[1];
    return price + " " + symbol;
  }

  const arrival = useSelector((state: AppState) => isReturnFlight ? state.dailyFlights.departure : state.dailyFlights.arrival);
  const departure = useSelector((state: AppState) => isReturnFlight ? state.dailyFlights.arrival : state.dailyFlights.departure);

  const classes = classNames(styles.flightPreviewContainer, className);

  return (
      <div className={classes}>
        {/*<div className={styles.flightPreviewTitle}>*/}
        {/*  {isReturnFlight ?*/}
        {/*      t("app.bookFlight.flights.flightPreview.return.title", "Return") :*/}
        {/*      t("app.bookFlight.flights.flightPreview.departure.title", "Departure")}:*/}
        {/*</div>*/}
        {/*<div className={styles.top}>*/}
        {/*  <div className={styles.airportName}>{departure.code}</div>*/}
        {/*  <Airplane />*/}
        {/*  <div className={styles.airportName}>{arrival.code}</div>*/}
        {/*</div>*/}
        <div className={styles.flightPreview}>
          <div className={styles.infoContainer}>
            <div className={styles.flightPreviewDetails}>
              <div className={styles.flightInfo}>
                <div className={styles.date}>
                  {moment(flight.dateOfDeparture, "DD/MM/YYYY").format("dddd, DD MMMM")}
                </div>
                <div className={styles.time}>
                  {moment(flight.timeOfDeparture, "hh:mm:ss").format("HH:mm")}
                </div>
                <div className={styles.airport}>{departure?.name}</div>
              </div>
              <Point className={styles.point}/>
              <div className={styles.horizontal}>
                <AirplaneIcon/>
                <p className={styles.flightTime}>Flight time: {flight.flightDuration}</p>
              </div>
              <Point className={styles.point}/>
              <div className={styles.flightInfo}>
                <div className={styles.date}>
                  {moment(flight.dateOfDeparture, "DD/MM/YYYY").format("dddd, DD MMMM")}
                </div>
                <div className={styles.time}>
                  {moment(flight.timeOfArrival, "hh:mm:ss").format("HH:mm")}
                </div>
                <div className={styles.airport}>{arrival?.name}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default FlightPreview;
