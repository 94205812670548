import {createSlice, Dispatch} from "@reduxjs/toolkit";
import {ResetPasswordTypes} from "../types/UserTypes";
import {ResetPasswordService} from "../actions";

interface resetPasswordStateParams {
    body: ResetPasswordTypes;
    onSuccess?: Function;
    onError?: Function;
}

export const resetPassword = (params: resetPasswordStateParams) => async (dispatch: Dispatch) => {
    try {
        dispatch(setLoading(true));
        const response = await ResetPasswordService.resetPassword(params.body);
        if(response.status >= 200 && response.status < 300) {
            dispatch(setSuccess(true));
            if(params.onSuccess) params.onSuccess();
            dispatch(setError(''));
            // @ts-ignore
        }
        dispatch(setLoading(false));
    }
    catch (err) {
        dispatch(setLoading(false));
        if(params.onError) params.onError();
        // dispatch(setError(err.message));
    }
}

interface resetPasswordSlice {
    success: boolean;
    error: string;
    loading: boolean;
}

const initialState: resetPasswordSlice = {
    error: '',
    success: false,
    loading: false
}

const stateSlice = createSlice({
    name: 'login/reset-password',
    initialState,
    reducers: {
        setLoading: (state, action) => {state.loading = action.payload},
        setSuccess: (state, action) => {state.success = action.payload},
        setError: (state, action) => {state.error = action.payload}
    }
})

export const {setLoading, setSuccess, setError} = stateSlice.actions;

export default stateSlice.reducer;