import {DatePicker, Form, Input, Radio} from 'antd';
import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState, useMemo, ReactNode} from 'react';
import styles from "./PassengerFormRow.module.scss";
import moment from "moment";
import classNames from "classnames";
import {useTranslation} from "react-i18next";
import Info from "../../assets/images/ic_round-info.svg"
// import ContactPerson from "../PassengerStep/ContactPerson";
import {useHistory} from "react-router-dom";
import {getQueryParameterByName} from "../../utils";
import PublicContactPerson from "../../containers/BookFlight/Overview/components/PublicContactPerson";
import ContactPerson from "../../containers/BookFlight/Overview/components/ContactPerson";

interface Props {
  type: PassengerType;
  passenger: any;
  onChange: any;
  isValidationVisible: boolean;
  index: number; // Add the index prop to determine if it's the first element
  // setNameError: any;
  // surnameError: any;
  // setSurnameError: any;
  // emailError: any;
  // setEmailError: any;
  // phoneError: any;
  // setPhoneError: any;
}

export enum PassengerType {
  ADULT = "ADULT",
  CHILD = "CHILD",
  INFANT = "INF"
}

const PassengerFormRow = forwardRef(({type, asd, index, passenger, onChange, isValidationVisible}: any, ref) => {

  const {t} = useTranslation();
  const history = useHistory();

  const contactPersonRef = useRef() as any;

  const [dateNotValid, setDateValid] = useState(false);

  const departureAirport = getQueryParameterByName("from");
  const arrivalAirport = getQueryParameterByName("to");
  const departureDate = getQueryParameterByName("departure");
  const returnDate = getQueryParameterByName("return");
  const adults = getQueryParameterByName("adults");
  const children = getQueryParameterByName("children");
  const infants = getQueryParameterByName("infants");
  const source = getQueryParameterByName("source");
  const language = getQueryParameterByName("language");

  useEffect(() => {
    setDateValid(disabledDates(passenger.dateOfBirth));
  }, []);

  const [nameError, setNameError] = useState<string>('');
  const [surnameError, setSurnameError] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [phoneError, setPhoneError] = useState<string>('');


  const nextStep = () => {
    history.push({
      pathname: "/booking/payment-methods",
      search: `?from=${departureAirport}&to=${arrivalAirport}&departure=${departureDate}${
          returnDate ? "&return=" + returnDate : ""
      }&adults=${adults}${children ? "&children=" + children : ""}${
          infants ? "&infants=" + infants : ""
      }${source ? "&source=" + source : ""}${language ? "&language=" + language : ""}`,
    });
  };

  const handleChange = (name: any, value: any) => {
    let newPassenger = {
      ...passenger,
      [name]: value
    };
    onChange(newPassenger);
    if (name === 'dateOfBirth') {
      console.log('YEARS:', moment().diff(value, 'years'));
      setDateValid(disabledDates(value))
    }

  }

  const PassengerTitle: any = {
    [PassengerType.ADULT]: t("app.bookFlight.passengers.passengerRow.adult", "Adult"),
    [PassengerType.CHILD]: t("app.bookFlight.passengers.passengerRow.child", "Child"),
    [PassengerType.INFANT]: t("app.bookFlight.passengers.passengerRow.baby", "Infant")
  }


  const disabledDates = (date: any) => {
    const current = moment(date, "DD/MM/YYYY");
    const years = moment().diff(current, "years");
    if (type === PassengerType.ADULT) {
      if (years < 15) {
        return true
      }
    } else if (type === PassengerType.CHILD) {
      if (years < 2 || years > 14) {
        return true
      }
    } else if (type === PassengerType.INFANT) {
      if (years > 1 || current.isAfter(moment())) {
        return true
      }
    }

    return false;
  }

  const phoneValidation = (!passenger.phoneNumber?.trim()) && isValidationVisible;
  const emailValidation = (!passenger.email?.trim()) && isValidationVisible;
  const genderValidation = (!passenger.gender?.trim()) && isValidationVisible;
  const nameValidation = (!passenger.name?.trim()) && isValidationVisible;
  const surnameValidation = (!passenger.surname?.trim()) && isValidationVisible;
  const birthdayValidation = (!passenger.dateOfBirth) && isValidationVisible;

  const validateEmail = (email: any) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if ((!passenger.email?.trim()) && isValidationVisible) {
      return setEmailError("Required");
    }
    else if (!emailRegex.test(email.target.value)) {
      return setEmailError('Invalid email format.')
    }
    else return setEmailError("");
  }

  const validateName = (e: any) => {
    const nameRegex = /^[A-Za-z]+$/;
   if (e.target.value === "") {
      return setNameError('Required');
    }
    else if (!nameRegex.test(e.target.value)) {
      return setNameError("Only alphabet are allowed");
    }
    else return setNameError('');
  }

  const validateSurname = (e: any) => {
      const surnameRegex = /^[A-Za-z]+$/;
    if (e.target.value === "") {
      return setSurnameError("Required");
    }
    else if (!surnameRegex.test(e.target.value)) {
      return setSurnameError("Only alphabet are allowed");
    }
    else return setSurnameError("");
  }

  const validatePhone = (e: any) => {
    const phoneRegex = /^\+\d+$/;

    if (e.target.value === "") {
      return setPhoneError("Required");
    }
    else if (!phoneRegex.test(e.target.value)) {
      return setPhoneError("'Invalid phone number. Please use numbers and a "+" symbol.'");
    }
    else return setPhoneError("");
  }

  const defaultPickerValue = () => {
    if (type == PassengerType.ADULT) {
      return moment().subtract(15, "years");
    } else if (type == PassengerType.CHILD) {
      return moment().subtract(2, "years");
    } else {
      return moment()
    }
  }

  useImperativeHandle(ref, () => ({

    dateNotValid() {
      return dateNotValid;
    }
  }));

  const KeyboardInputDate = (e: any) => {
    if (e.which !== 8) {
      const numChars = e.target.value.length;
      if (numChars === 2 || numChars === 5) {
        let thisVal = e.target.value;
        thisVal += '/';
        e.target.value = thisVal;
      }
    }
  }

  return (
      <div style={{marginBottom: "3rem"}}>
        <div className={styles.passengerFormRowTitle}>
          <b>{PassengerTitle[type]} {t("app.bookFlight.passengers.passengerRow.passenger", "Passenger Details")}</b>
        </div>
        <div className={styles.passengerFormRow}>
          <div className={classNames(styles.col, {[styles.hasError]: nameError !== '' || nameValidation})}>
            <input
                placeholder={t("app.bookFlight.passengers.passengerRow.name.label", "FIRST NAME")}
                type="text"
                id="name"
                pattern="[A-Za-z]+"
                value={passenger.name}
                className={styles.passengerFormRowInput}

                onChange={(e) => {
                  handleChange("name", e.target.value);
                  validateName(e);
                }}/>
            {nameValidation ? <p
                style={{marginTop: '5px', color: 'red'}}>{t("app.validations.required", "Required")}</p>
            : <p style={{marginTop: '5px', color: 'red'}}>{nameError}</p>}
          </div>

          <div className={classNames(styles.col, {[styles.hasError]: surnameValidation || surnameError !== ""})}>
            <input
                placeholder={t("app.bookFlight.passengers.passengerRow.surname.label", "LAST NAME")}
                type="text"
                id="surnameInput"
                value={passenger.surname}
                className={styles.passengerFormRowInput}
                onChange={(e) => {
                  handleChange("surname", e.target.value);
                  validateSurname(e);
                }}/>
            {surnameValidation ? <p
                style={{marginTop: '5px', color: 'red'}}>{t("app.validations.required", "Required")}</p> :
            <p style={{marginTop: '5px', color: 'red'}}>{surnameError}</p>}
          </div>

          <div className={classNames(styles.col, {[styles.hasError]: birthdayValidation || dateNotValid})}>
            <DatePicker
                className="passengerFormRowDatePicker"
                defaultPickerValue={defaultPickerValue()}
                placeholder={t("app.bookFlight.passengers.passengerRow.birthday.label", "BIRTHDAY")}
                id="birthdayInput"
                format="DD/MM/YYYY"
                value={passenger.dateOfBirth ? moment(passenger.dateOfBirth, "DD/MM/YYYY") : null}
                onChange={(date) => handleChange("dateOfBirth", date?.format("DD/MM/YYYY"))}
                onKeyDown={KeyboardInputDate}
                inputReadOnly
            />
            {birthdayValidation && <p
                style={{marginTop: '5px', color: 'red'}}>{t("app.validations.required", "Required")}</p>}
            {(dateNotValid && !birthdayValidation) &&
                <span className={styles.validationMessage}>Date is not valid </span>}
          </div>
          <div className={classNames(styles.radioButtons, {[styles.hasError]: genderValidation})}>
            <Radio.Group value={passenger.gender}
                         onChange={(e) => handleChange("gender", e.target.value)}>
              <Radio value="FEMALE">{t("app.bookFlight.passengers.passengerRow.female", "Female")}</Radio>
              <Radio value="MALE">{t("app.bookFlight.passengers.passengerRow.male", "Male")}</Radio>
            </Radio.Group>
            {genderValidation && <p
                style={{marginTop: '5px', color: 'red'}}>{t("app.validations.required", "Required")}</p>}
          </div>
          {index === 0 && (
              <div>
          <div className={classNames(styles.col, {[styles.hasError]: emailError !== '' || emailValidation})}>
            <input placeholder={t("app.contactPerson.email", "EMAIL")}
            value={passenger.email}
                   id="email"
                   type="email"
            className={styles.passengerFormRowInput}
            onChange={(e) => {
            handleChange("email", e.target.value);
            validateEmail(e);
          }}/>
            {emailValidation ? <p
                    style={{marginTop: '5px', color: 'red'}}>{t("app.validations.required", "Required")}</p>
             : <p style={{marginTop: '5px', color: 'red'}}>{emailError}</p>}
          </div>
          <div className={classNames(styles.col, {[styles.hasError]: phoneValidation || phoneError !== ""})}>
            <input placeholder={t("app.contactPerson.phoneNumber", "PHONE")}
                   id="phone"
                   type="tel"
                   value={passenger.phoneNumber}
                   className={styles.passengerFormRowInput}
                   onChange={(e) => {
                     handleChange("phoneNumber", e.target.value);
                     validatePhone(e);
                   }}/>
            {phoneValidation ? <p
                style={{marginTop: '5px', color: 'red'}}>{t("app.validations.required", "Required")}</p> :
            <p style={{marginTop: '5px', color: 'red'}}>{phoneError}</p>}
          </div>
                <div className={styles.infoMessage}>
                  <img src={Info}/>
                  <p>
                    Please make sure that your phone number and email address are correct
                    in case we need to inform you of any changes to your flight.
                  </p>
                </div>
              </div>
            )}
          {/*{index === 0 && (*/}
          {/*    <div>*/}
          {/*      <ContactPerson ref={contactPersonRef} onFinishForm={nextStep} />*/}
          {/*      <div className={styles.infoMessage}>*/}
          {/*        <img src={Info}/>*/}
          {/*        <p>*/}
          {/*          Please make sure that your phone number and email address are correct*/}
          {/*          in case we need to inform you of any changes to your flight.*/}
          {/*        </p>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*)}*/}
        </div>
      </div>
  )
})

export default PassengerFormRow;
