import {createSlice, Dispatch} from "@reduxjs/toolkit";
import {CustomersService} from "../actions"
import {AccountInfoBody} from "../types/service-types/CustomerTypes";

interface UpdateAccountInfoStateParams {
    body: AccountInfoBody;
    onSuccess?: Function;
    onError?: Function;
}

export const updateAccountInfo = (params: any) => async (dispatch: Dispatch) => {
    try {
        dispatch(setLoading(true));
        const response = await CustomersService.updateAccountInfo(params);
        if(response.status >= 200 && response.status < 300) {
            dispatch(setSuccess(true));
            if(params.onSuccess) params.onSuccess();
            dispatch(setError(''));
            // @ts-ignore
        }
        dispatch(setLoading(false));
    }
    catch(err) {
        dispatch(setLoading(false));
        if(params.onError) params.onError();
        // dispatch(setError(err.message));
    }
}

interface UpdateAccountInfoSlice {
    success: boolean;
    error: string;
    loading: boolean;
}

const initialState: UpdateAccountInfoSlice = {
    error: '',
    success: false,
    loading: false
}

const stateSlice = createSlice({
    name: 'myaccount/update-account',
    initialState,
    reducers: {
        setLoading: (state, action) => {state.loading = action.payload},
        setSuccess: (state, action) => {state.success = action.payload},
        setError: (state, action) => {state.error = action.payload}
    }
});

export const {setLoading, setSuccess, setError} = stateSlice.actions;

export default stateSlice.reducer;
