import React from 'react'
import styles from "./HomepageOfferCard.module.scss";
import {ReactComponent as AirplaneIcon} from "../../assets/images/airplane.svg";
import classNames from "classnames";

interface Props {
    offer: any;
    onClick: any;
    className?: any;
    small?: boolean;
}

const HomepageOfferCard = ({offer, onClick, className, small}: Props) => {
    return (
        <div onClick={onClick} className={classNames(styles.homepageImageCard, className, {[styles.small]: small})}
            style={{ backgroundImage: "url("+ offer.backdropImageUrl +")" }}>
            <div className={styles.overlay} />
            <div className={styles.top}>
                <span className={styles.title}>{offer.departureLocation.name}</span>
                <AirplaneIcon />
                <span className={styles.title}>{offer.arrivalLocation.name}</span>
                {/*<span className={styles.status}>new destination</span>*/}
            </div>
            <span className={styles.subtitle}>{offer.price}</span>
        </div>
    )

}

export default HomepageOfferCard;
